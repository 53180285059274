import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Default: localStorage for web
import { combineReducers } from "redux";

// Import your reducers
import searchReducer from "./postSearch/searchSlice";
import tagReducer from "./tagFilter/tagSlice";
import postReducer from "./postEdit/postSlice";
import activeNotificationReducer from "./activeNotifications/activeNotificationSlice";
import languageReducer from "./languageSlice";
import userSearchReducer from "./userSearch/userSearchSlice";
import chatReducer from "./chat/chatSlice";
import unreadMessageReducer from "./unread/unreadMessageSlice";
import userReducer from "./userdata/userSlice";
import subscriptionReducer from "./subscription/subscriptionSlice";
import inputValueReducer from "./inputValueSlice";

// 1. Define persist configuration
const persistConfig = {
  key: "root", // Key for storage root
  storage, // Storage type
  whitelist: ["search", "tag", "language", "user"], // Reducers to persist
};

// 2. Combine all reducers
const rootReducer = combineReducers({
  search: searchReducer,
  tag: tagReducer,
  subscription: subscriptionReducer,
  chat: chatReducer,
  inputValue: inputValueReducer,
  post: postReducer,
  activeNotifications: activeNotificationReducer,
  language: languageReducer,
  userSearch: userSearchReducer,
  unreadMessage: unreadMessageReducer,
  user: userReducer,
});

// 3. Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// 4. Configure the store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable if you encounter non-serializable values
    }),
});

// 5. Create the persistor
export const persistor = persistStore(store);

export default store;
