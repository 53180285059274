import React from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useDispatch } from "react-redux";
import { BsThreeDots } from "react-icons/bs";
import { FaBookmark } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";
import avatar from "../../assets/lady.jpg";
import { GoDotFill } from "react-icons/go";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { clearSearchText } from "../../redux-store/postSearch/searchSlice"; // import clear actions
import { clearUserSearchText } from "../../redux-store/userSearch/userSearchSlice"; // import clear actions

const AllUserList = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const popUpAnimation = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };
  const handleLinkClick = () => {
    dispatch(clearSearchText());
    dispatch(clearUserSearchText());
  };
  return (
    <div className="w-full ">
      {data.length > 0 ? (
        data
          .filter((post) => post.username) // Exclude posts with null or undefined username
          .map((post, index) => (
            <motion.div
              key={index}
              className="p-2 px-4 bg-white rounded-xl mb-3 w-full scrollbar-hide "
              initial="hidden"
              animate="visible"
              variants={popUpAnimation}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              <div className="hover:scale-105 ease-in-out transition-transform duration-300 mb-7 ">
                <div className="flex w-full">
                  {/* Adjusted width */}
                  <div className="w-fit">
                    {post.profile_image &&
                      post.profile_image !== "default.png" &&
                      post.profile_image !== "" && (
                        <img
                          className={`w-9 h-8 md:w-14 md:h-12 lg:w-14 lg:h-12 rounded-full object-cover cursor-pointer flex-shrink-0 ${
                            post.type?.toLowerCase() === "employer"
                              ? "ring-4 ring-orange-500"
                              : "ring-4 ring-neutral-700"
                          }`}
                          src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${post.profile_image}`}
                          alt="User Avatar"
                          onClick={() => navigate(`/user/${post.id}`)}
                          style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                        />
                      )}

                    {(!post.profile_image ||
                      post.profile_image === "default.png" ||
                      post.profile_image === "") && (
                      <FaUserCircle
                        onClick={() => navigate(`/user/${post.id}`)}
                        className={`w-8 h-8 md:w-12 md:h-12 lg:w-12 lg:h-12 text-[2rem] rounded-full text-neutral-400 cursor-pointer flex-shrink-0 ${
                          post.type?.toLowerCase() === "employer"
                            ? "ring-4 ring-orange-500"
                            : "ring-4 ring-neutral-700"
                        }`}
                      />
                    )}
                  </div>

                  <div className="flex flex-col ml-4 mb-2 w-full">
                    <Link
                      className="text-sm md:text-lg lg:text-lg font-bold text-neutral-600"
                      to={`/user/${post.id}`}
                      onClick={handleLinkClick}
                    >
                      {post.first_name} {post.last_name}
                    </Link>
                    <div
                      className={`text-sm md:text-lg lg:text-lg flex items-center text-[#5B5B5B] ${
                        post.username === "@premium" ||
                        post.username === "@Test"
                          ? "text-white"
                          : ""
                      }`}
                    >
                      {post.username}
                      <GoDotFill
                        className={`ml-1 text-xs mr-1 ${
                          post.user?.type === "job-seeker"
                            ? "text-black"
                            : "text-[#E7831D]"
                        }`}
                      />
                      <span
                        className={`flex items-center ${
                          post.type === "job-seeker"
                            ? "text-black"
                            : "text-orange-400"
                        }`}
                      >
                        {capitalizeFirstLetter(post.type)}
                        <IoIosCheckmarkCircle className="ml-1" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            </motion.div>
          ))
      ) : (
        <div> </div>
      )}
    </div>
  );
};

export default AllUserList;
