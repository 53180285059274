import React, { useState } from "react";
import axios from "axios";
import { FaCircleCheck } from "react-icons/fa";
import fillCheck from "../../assets/fillcheck (1).svg";
import { Space, Spin } from "antd";
import { LocalDiningOutlined } from "@mui/icons-material";
import { LoadingOutlined } from "@ant-design/icons";
import { analytics } from "../../firebase"; // Import Firebase Analytics
import { logEvent } from "firebase/analytics";
const BasePlan = ({
  textsExtra,
  subscriptionData,
  formatDate,
  cancelId,
  subscriptionDuration,
  duration,
}) => {
  const [loading, setLoading] = useState(false);
  console.log(subscriptionDuration, "subscriptionData");
  const handleCancelSubscription = async () => {
    try {
      setLoading(true);
      logEvent(analytics, "cancel_subscription", {
        price: subscriptionData.price,
        timestamp: Date.now(),
      });
      const baseUrl = process.env.REACT_APP_API_BASE_URL; // Get the base URL from .env
      const token = localStorage.getItem("token"); // Retrieve the token from localStorage

      if (!token) {
        return;
      }

      const response = await axios.delete(
        `${baseUrl}/api/v1/admin/stripe/subscriptions/${cancelId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
          },
        }
      );
      setLoading(false);

      // Handle the success response
      window.location.reload();
      console.log(response.data);
    } catch (error) {
      // Handle errors
      console.error(error);
      setLoading(false);
    }
  };
  const calculateSubscriptionDuration = (subscriptionDuration) => {
    if (!subscriptionDuration) return "Unknown Duration";

    let parsedData;
    try {
      parsedData = JSON.parse(subscriptionDuration); // Convert string to object
    } catch (error) {
      return "Invalid Data"; // Handle parsing errors
    }

    const { purchased_at_ms, expiration_at_ms } = parsedData;

    if (!purchased_at_ms || !expiration_at_ms)
      return "Invalid Subscription Data";

    const purchasedAt = new Date(purchased_at_ms);
    const expirationAt = new Date(expiration_at_ms);

    if (isNaN(purchasedAt.getTime()) || isNaN(expirationAt.getTime())) {
      return "Invalid Date";
    }

    const differenceMs = expirationAt.getTime() - purchasedAt.getTime();
    const totalMinutes = Math.floor(differenceMs / (1000 * 60));
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);

    const remainingHours = totalHours % 24;
    const remainingMinutes = totalMinutes % 60;

    let durationString = "";
    if (totalDays > 0) durationString += `${totalDays} Days `;
    if (remainingHours > 0) durationString += `${remainingHours} Hours `;
    if (remainingMinutes > 0) durationString += `${remainingMinutes} Minutes`;

    return durationString.trim() || "Less than a minute";
  };

  return (
    <div className="mt-4 mx-auto shadow-[rgba(0,_0,_0,_0.24)_0px_1px_2px] rounded-2xl flex items-center text-center flex-col space-y-5 py-6 w-12/12 md:w-11/12 lg:w-11/12">
      {/* <FaCircleCheck className="text-[4rem] mt-10 text-[#404041]" /> */}
      <img src={fillCheck} alt="" />
      {subscriptionData.price === "9.99" ? (
        <>
          <h2 className="text-sm md:text-xl lg:text-xl font-extrabold text-[#404041]">
            {textsExtra.piad}
          </h2>
          <p className="text-xs lg:text-sm px-5 md:px-14 lg:px-14 text-justify text-[#404041]">
            {textsExtra.paidTexts}
          </p>
        </>
      ) : (
        <>
          <h2 className="text-sm md:text-xl lg:text-xl font-extrabold text-[#404041]">
            {textsExtra.Premium}
          </h2>
          <p className="text-xs lg:text-sm px-5 md:px-14 lg:px-14 text-justify text-[#404041]">
            {textsExtra.premiumText}
          </p>
        </>
      )}

      <div className="flex w-10/12 justify-between text-sm">
        <span className="font-bold">{textsExtra.value}</span>
        <span className="font-bold">$ {subscriptionData.price}</span>
      </div>
      <div className="flex w-10/12 justify-between text-sm">
        <span className="font-bold">{textsExtra.Duration}</span>
        <span className="font-bold">
          {calculateSubscriptionDuration(subscriptionDuration)}
        </span>
      </div>
      <div className="flex w-10/12 justify-between text-sm">
        <span className="font-bold">{textsExtra.Renews}</span>
        <span className="font-bold">
          {formatDate(subscriptionData.updated_at)}
        </span>
      </div>
      <button
        className="py-2 bg-[#404041] text-white text-xs md:text-sm lg:text-sm lg:px-20 md:px-10 px-5 font-bold rounded-full shadow-sm w-8/12 mb-3 hover:scale-95 hover:shadow-lg"
        onClick={handleCancelSubscription}
      >
        {loading ? (
          <Space className="mr-3">
            <Spin
              indicator={
                <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
              }
              size="medium"
            />
          </Space>
        ) : (
          <span>{textsExtra.cancelSubscription}</span>
        )}
      </button>
    </div>
  );
};

export default BasePlan;
