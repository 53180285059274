import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import OtpScreen from "./pages/OtpScreen";
import ForgetPassword from "./pages/ForgetPassword";
import NewPassword from "./pages/NewPassword";
import { Toaster } from "sonner";
import Footer from "./components/Footer";
import MainLogoLoader from "./components/loading/MainLogoLoader";
import TermAndCondition from "./pages/TermAndCondition";
import OtpScreenNew from "./pages/OtpScreenNew";
import Hello from "./pages/Hello";
import EmailEnterForgetPassword from "./pages/EmailEnterForgetPassword";
import HomeExperimental from "./pages/HomeExperimental";
import TermandconditionSettings from "./pages/TermandconditionSettings";
import useUnreadMessageCount from "./unread/useUnreadMessageCount";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import AccessToken from "./components/mini-components/AccessToken";
import UserDetailsSocial from "./pages/UserDetailsSocial";
import AccessTokenTwo from "./components/mini-components/AccessTokenTwo";
import MetaTags from "./Metadescription/MetaTags";
import logo from "./assets/barcodecenter.png";
import TermAndConditionMobile from "./pages/TermAndConditionMobile";
import TermAndConditionMobileSp from "./pages/TermAndConditionMobileSp";
import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";
import { logTrafficSource, detectDeviceInfo } from "./analytics";
import { getUTMParams } from "./utils";
function App() {
  const [showAppModal, setShowAppModal] = useState(false);
  useEffect(() => {
    const { source, medium } = getUTMParams();
    detectDeviceInfo();
    logTrafficSource(source, medium);
  }, []);
  useEffect(() => {
    // Detect if the user is on a mobile device and not on excluded routes
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const excludedRoutes = [
      "/set/policy",
      "/policy/view/sp",
      "/policy/view",
      "/policy",
      6,
    ];
    const currentPath = window.location.pathname;

    const isExcludedRoute = excludedRoutes.some((route) =>
      currentPath.startsWith(route)
    );

    if (isMobile && !isExcludedRoute) {
      handleAppOpen();
    }
  }, []);

  const handleAppOpen = () => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const currentPath = window.location.pathname; // Get the current route

    if (isAndroid) {
      window.location.href = `intent://bocaenboca.app${currentPath}#Intent;scheme=https;package=com.bocaenboca.app;end;`;
    } else if (isIOS) {
      window.location.href = `bocaenboca://${currentPath}`;
    }
  };

  const handleWebContinue = () => {
    setShowAppModal(false);
  };
  useEffect(() => {
    logEvent(analytics, "page_view", { page_path: window.location.pathname });
  }, []);
  return (
    <Router>
      <div className="App h-screen">
        <Toaster richColors />

        <Routes>
          <Route
            path="/*"
            element={
              <>
                <MetaTags
                  title="Boca En Boca"
                  description="Connect Directly With #Employers To Find A #Job"
                  url="https://bocaenboca.app"
                />
                <HomeExperimental />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <MetaTags
                  title="Login - Boca En Boca"
                  description="Login to connect directly with employers and find jobs easily."
                  url="https://bocaenboca.app/login"
                />
                <Login />
              </>
            }
          />
          <Route
            path="/9974590"
            element={
              <>
                <MetaTags
                  title="Loader - Boca En Boca"
                  description="Connecting you to the best opportunities."
                  url="https://bocaenboca.app/9974590"
                />
                <MainLogoLoader />
              </>
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <MetaTags
                  title="Signup - Boca En Boca"
                  description="Sign up to start your journey to find the perfect job!"
                  url="https://bocaenboca.app/signup"
                />
                <SignUp />
              </>
            }
          />
          <Route
            path="/otp"
            element={
              <>
                <MetaTags
                  title="OTP Verification - Boca En Boca"
                  description="Verify your account with a one-time password."
                  url="https://bocaenboca.app/otp"
                />
                <OtpScreen />
              </>
            }
          />
          <Route
            path="/verify-otp"
            element={
              <>
                <MetaTags
                  title="Verify OTP - Boca En Boca"
                  description="Enter your OTP to verify your account."
                  url="https://bocaenboca.app/verify-otp"
                />
                <OtpScreenNew />
              </>
            }
          />
          <Route
            path="/forget-password"
            element={
              <>
                <MetaTags
                  title="Forget Password - Boca En Boca"
                  description="Reset your password to regain access to your account."
                  url="https://bocaenboca.app/forget-password"
                />
                <ForgetPassword />
              </>
            }
          />
          <Route
            path="/email-fp"
            element={
              <>
                <MetaTags
                  title="Enter Email - Boca En Boca"
                  description="Enter your email to reset your password."
                  url="https://bocaenboca.app/email-fp"
                />
                <EmailEnterForgetPassword />
              </>
            }
          />
          <Route
            path="/new-password"
            element={
              <>
                <MetaTags
                  title="Set New Password - Boca En Boca"
                  description="Set a new password to secure your account."
                  url="https://bocaenboca.app/new-password"
                />
                <NewPassword />
              </>
            }
          />
          <Route
            path="/policy/:name"
            element={
              <>
                <MetaTags
                  title="Policy - Boca En Boca"
                  description="Learn more about our terms and conditions."
                  url="https://bocaenboca.app/policy/:name"
                />
                <TermAndCondition />
              </>
            }
          />
          <Route
            path="/policy/view/:name"
            element={
              <>
                <MetaTags
                  title="Policy - Boca En Boca"
                  description="Learn more about our terms and conditions."
                  url="https://bocaenboca.app/policy/:name"
                />
                <TermAndConditionMobile />
              </>
            }
          />
          <Route
            path="/policy/view/sp/:name"
            element={
              <>
                <MetaTags
                  title="Policy - Boca En Boca"
                  description="Learn more about our terms and conditions."
                  url="https://bocaenboca.app/policy/:name"
                />
                <TermAndConditionMobileSp />
              </>
            }
          />
          <Route
            path="/linkedin/callback"
            element={
              <>
                <MetaTags
                  title="LinkedIn Callback - Boca En Boca"
                  description="Authenticate via LinkedIn to connect with employers."
                  url="https://bocaenboca.app/linkedin/callback"
                />
                <LinkedInCallback />
              </>
            }
          />
          <Route
            path="/backend/linkedin/login"
            element={
              <>
                <MetaTags
                  title="LinkedIn Login - Boca En Boca"
                  description="Logging you in using LinkedIn."
                  url="https://bocaenboca.app/backend/linkedin/login"
                />
                <AccessToken />
              </>
            }
          />
          <Route
            path="/backend/facebook/login"
            element={
              <>
                <MetaTags
                  title="Facebook Login - Boca En Boca"
                  description="Logging you in using Facebook."
                  url="https://bocaenboca.app/backend/facebook/login"
                />
                <AccessTokenTwo />
              </>
            }
          />
          <Route
            path="/userdetails/:firstName/:email/:uniqueCode/:platform/*"
            element={
              <>
                <MetaTags
                  title="User Details - Boca En Boca"
                  description="Provide your details to complete the registration process."
                  url="https://bocaenboca.app/userdetails/:firstName/:email/:uniqueCode/:platform/"
                />
                <UserDetailsSocial />
              </>
            }
          />
          <Route
            path="/set/policy/:name"
            element={
              <>
                <MetaTags
                  title="Policy Settings - Boca En Boca"
                  description="Manage your policy settings here."
                  url="https://bocaenboca.app/set/policy/:name"
                />
                <TermandconditionSettings />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
