import React from "react";
import PostShimmer from "./PostShimmer";

const ProfileShimmer = () => {
  return (
    <div className="flex relative flex-col">
      <div className="w-full h-44 bg-neutral-200 animate-pulse"></div>
      <div className="absolute w-44 h-44 top-16 left-8 rounded-full bg-neutral-300 animate-pulse"></div>

      <div className="flex gap-2 mt-20 ml-12">
        {" "}
        <span className="w-20 h-3 rounded-full bg-neutral-300 animate-pulse"></span>{" "}
        <span className="w-36 h-3 rounded-full bg-neutral-300 animate-pulse"></span>
        <span className="w-36 h-3 rounded-full bg-neutral-300 animate-pulse"></span>
      </div>
      <div className="flex gap-2 mt-4 ml-14">
        {" "}
        <span className="w-10 h-3 rounded-full bg-neutral-300 animate-pulse"></span>{" "}
        <span className="w-36 h-3 rounded-full bg-neutral-300 animate-pulse"></span>
      </div>
      <div className="flex gap-2 mt-4 ml-14">
        {" "}
        <span className="w-36 h-3 rounded-full bg-neutral-300 animate-pulse"></span>
      </div>
      <div className="flex gap-2 mt-4 ml-14">
        {" "}
        <span className="w-4/5 h-3 rounded-full bg-neutral-300 animate-pulse"></span>
      </div>
      <div className="flex gap-2 mt-1 ml-14">
        {" "}
        <span className="w-3/5 h-3 rounded-full bg-neutral-300 animate-pulse"></span>
      </div>
      <div className="flex gap-2 mt-1 ml-14 mb-5">
        {" "}
        <span className="w-2/5 h-3 rounded-full bg-neutral-300 animate-pulse"></span>
      </div>
      <hr />
      <PostShimmer />
    </div>
  );
};

export default ProfileShimmer;
