import React from "react";

const SmallSpinner = () => {
  return (
    <div class="flex-col gap-4 w-full flex items-center justify-center h-96">
      <div class="w-20 h-20 border-8 text-neutral-400 text-4xl animate-spin border-neutral-300 flex items-center justify-center border-t-white rounded-full"></div>
    </div>
  );
};

export default SmallSpinner;
