import React, { useEffect, useState } from "react";
import { FaLinkedin, FaFacebookF, FaGoogle } from "react-icons/fa";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";
import SccialLoading from "../loading/SccialLoading";
import RoleSelectionModal from "./RoleSelectionModal";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";
const SocialLogin = () => {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [socialOptions, setSocialOptions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    fetchSocialLoginOptions();
  }, []);

  const fetchSocialLoginOptions = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/v1/social/get-social-login`
      );
      setSocialOptions(response.data.content);
    } catch (error) {
      console.error("Error fetching social login options:", error);
      toast("Failed to load login options", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    } finally {
      setLoading(false);
    }
  };

  const isOptionEnabled = (keyName) => {
    const option = socialOptions.find(
      (opt) => opt.key_name === `${keyName}_social_login`
    );
    return option?.live_values === "1";
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true);
      try {
        const userInfoResponse = await axios.get(
          `https://www.googleapis.com/oauth2/v3/userinfo`,
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );

        const {
          given_name: first_name,
          family_name: last_name,
          email,
          sub: unique_id,
        } = userInfoResponse.data;

        setUserInfo({ first_name, last_name, email, unique_id });

        const checkUserResponse = await axios.post(
          `${baseURL}/api/v1/customer/auth/social-login`,
          { email, unique_id, fcm_token: "" }
        );

        if (checkUserResponse.status === 200) {
          const { token } = checkUserResponse.data.content;
          localStorage.setItem("token", token);
          navigate("/");
        } else {
          setIsModalVisible(true);
          setSelectedRole(null);
        }
      } catch (error) {
        console.error("Error during Google login:", error);
        toast("Google login failed. Please select your role.", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
        setIsModalVisible(true);
      } finally {
        setLoading(false);
      }
    },
    onError: () => {
      toast("Google login failed. Please try again.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    },
  });

  const handleRoleSelect = async (role) => {
    setSelectedRole(role);

    if (selectedStateId && selectedCityId) {
      const { first_name, last_name, email, unique_id } = userInfo;

      const requestBody = {
        first_name,
        last_name,
        email,
        unique_id,
        medium: "google",
        type: role,
        country_id: "233",
        state_id: String(selectedStateId),
        city_id: String(selectedCityId),
      };

      const apiResponse = await axios.post(
        `${baseURL}/api/v1/customer/auth/social-registration`,
        requestBody
      );

      const { token } = apiResponse.data.content;
      localStorage.setItem("token", token);
      logEvent(analytics, "registration_success", {
        role: role,
        medium: "google",
      });
      navigate("/");
      toast("Registration successful!", {
        style: { backgroundColor: "#333", color: "#fff" },
      });

      setIsModalVisible(false);
    }
  };

  if (loading) {
    return <SccialLoading />;
  }

  return (
    <div className="w-full md:w-full lg:w-1/2 h-auto flex justify-center gap-5 items-center  mt-5 mx-auto">
      {isOptionEnabled("linkedin") && (
        <FaLinkedin
          onClick={() => navigate("/backend/linkedin/login")}
          className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
        />
      )}
      {isOptionEnabled("facebook") && (
        <FaFacebookF
          onClick={() => navigate("/backend/facebook/login")}
          className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
        />
      )}
      {isOptionEnabled("google") && (
        <FaGoogle
          onClick={handleGoogleLogin}
          className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
        />
      )}

      <Toaster position="top-right" />
      <RoleSelectionModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSelectRole={handleRoleSelect}
        setSelectedStateId={setSelectedStateId}
        setSelectedCityId={setSelectedCityId}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
      />
    </div>
  );
};

export default SocialLogin;
