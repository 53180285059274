import React, { Fragment, useState, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";
import bblogo from "../../assets/276pxlogo.svg";
import crossdialog from "../../assets/crossdialog.svg";
import PleaseLoginModal from "../../pages/PleaseLoginModal";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  logo,
  navigate,
  navigation,
  handleNavigationClick,
  activeNotificationCount,
  userExists,
  sidebarOption,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const SvgIcon = ({ src, className, ...props }) => (
    <img src={src} className={className} alt="" {...props} />
  );

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative " onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-neutral-900/80 mainindex" />
          </Transition.Child>

          <div className="fixed inset-0 flex  mainindex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1 ">
                <div className="absolute  top-5 right-2 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <img
                      src={crossdialog}
                      alt="crossicon"
                      className="h-6 w-6 mb-2 "
                    />
                  </button>
                </div>

                <div className="flex grow flex-col gap-y-2 border-r border-neutral-200 bg-white px-8 md:px-14 overflow-y-auto ">
                  <nav className="flex flex-1 flex-col mt-5">
                    <img
                      src={bblogo}
                      alt="logo"
                      className="w-[120px] md:w-[146px] mt-5 cursor-pointer"
                      onClick={() => navigate("/")}
                    />
                    <ul role="list" className="flex flex-1 flex-col gap-y-5">
                      <li>
                        <ul
                          role="list"
                          className="-mx-2 space-y-1 font-bold mt-10"
                        >
                          {navigation.map((item) => {
                            const isRestricted =
                              !userExists &&
                              ![
                                sidebarOption.Home,
                                sidebarOption.Explore,
                                sidebarOption.LogIn,
                                sidebarOption.Settings,
                                sidebarOption.Subscription,
                              ].includes(item.name);

                            return (
                              <li key={item.name}>
                                <Link
                                  to={isRestricted ? "#" : item.href}
                                  onClick={(e) => {
                                    if (isRestricted) {
                                      e.preventDefault();
                                      setSidebarOpen(false);
                                      setIsLoginModalVisible(true);
                                      return;
                                    }
                                    handleNavigationClick(item.name);
                                  }}
                                  className={classNames(
                                    item.current
                                      ? "text-[#E7831D] relative"
                                      : isRestricted
                                      ? "text-gray-400 cursor-not-allowed"
                                      : "text-[#404041] hover:text-[#E7831D]",
                                    "group flex gap-x-3 rounded-md p-2 text-md leading-7 font-light"
                                  )}
                                >
                                  {item.name === "Notifications" &&
                                    userExists &&
                                    activeNotificationCount > 0 && (
                                      <div className="flex justify-center items-center absolute">
                                        <span className="bg-[#E7831D] w-6 h-6 rounded-full animate-spin absolute filter z-40 ml-1"></span>
                                        <span className="rounded-full absolute text-white z-50 flex justify-center items-center text-xs ml-1">
                                          {activeNotificationCount}
                                        </span>
                                      </div>
                                    )}

                                  <SvgIcon
                                    src={
                                      item.current ? item.iconActive : item.icon
                                    }
                                    className={classNames(
                                      "h-5 w-5 shrink-0 group-hover:hidden",
                                      isRestricted
                                        ? "opacity-30"
                                        : "opacity-100"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <SvgIcon
                                    src={item.iconActive}
                                    className="h-6 w-6 hidden group-hover:block"
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={
                                      item.current
                                        ? "font-bold text-md"
                                        : "text-md"
                                    }
                                  >
                                    {item.name}
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                  <p className="relative bg-white flex text-left items-center left-0 bottom-2 w-58 justify-center z-50 text-md text-neutral-600 text-[.6rem]">
                    <a href="https://www.bocaenboca.com" target="_blank">
                      <span className="mr-1">Boca En Boca</span> is a trademark
                      of The Peddler Group, Inc. Copyright 2022.
                    </a>
                  </p>
                  <p className="relative bg-white flex flex-wrap text-left items-left left-0 bottom-2 w-58 justify-left z-50 text-md text-neutral-600 text-[.6rem]">
                    <Link to="/set/policy/terms_&_conditions">
                      Terms & Conditions
                    </Link>
                    <Link to="/set/policy/privacy_policy" className="ml-1">
                      {" "}
                      Privacy Policy
                    </Link>
                    <Link to="/set/policy/cancellation_&_refund_policy">
                      {" "}
                      Cancellation & Refund Policy
                    </Link>
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
    </>
  );
};

export default Sidebar;
