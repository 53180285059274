import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  setUserSearchText,
  clearUserSearchText,
} from "../../redux-store/userSearch/userSearchSlice";
import AllUserList from "./AllUserList";
import translations from "../../languages";
import { useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
const UserList = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].headings;
  const inputValue = useSelector((state) => state.inputValue.inputValue);
  const activeTags = useSelector((state) => state.tag.activeTags);
  const [selectedTag, setSelectedTag] = useState("");
  const userSearchText = useSelector(
    (state) => state.userSearch.userSearchText
  );
  console.log(userSearchText, "userSearchText");
  const [searchResults, setSearchResults] = useState([]);
  const [showUserList, setShowUserList] = useState(false); // New state to toggle visibility
  const location = useLocation();

  useEffect(() => {
    const storedTag = localStorage.getItem("selectedTag");
    setSelectedTag(storedTag);
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "selectedTag") {
        setSelectedTag(event.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // This useEffect will be triggered every time `activeTags` or `userSearchText` changes.
  useEffect(() => {
    const fetchData = async () => {
      // API will be called only if:
      // 1. activeTags includes required tags OR
      // 2. userSearchText has more than 2 characters
      if (
        activeTags.includes("emp_profile") ||
        activeTags.includes("job_seeker_profile") ||
        activeTags.includes("both") ||
        userSearchText.length > 2
      ) {
        const params = {
          limit: 100,
          offset: 1,
          string: userSearchText || inputValue,
        };

        // Dynamically add filters based on the tags array
        if (activeTags.includes("emp_profile")) {
          params.emp_profile = 1;
        }
        if (activeTags.includes("job_seeker_profile")) {
          params.job_seeker_profile = 1;
        }
        if (activeTags.includes("both")) {
          params.emp_profile = 1;
          params.job_seeker_profile = 1;
        }

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/explorer_user_profile_list`,
            { params }
          );
          setSearchResults(response.data.content.data);
        } catch (error) {
          console.error("Error fetching user search results:", error);
        }
      } else {
        setSearchResults([]); // Clear search results if criteria are not met
      }
    };

    fetchData();
  }, [userSearchText, activeTags, selectedTag]); // Trigger fetch whenever activeTags or userSearchText changes

  useEffect(() => {
    // Expand the user list whenever activeTags change
    setShowUserList(true); // Automatically expand the list on tag change
  }, [activeTags]); // Add activeTags to the dependency array

  // Toggle the visibility of the user list
  const toggleUserList = () => {
    setShowUserList(!showUserList);
  };

  return (
    <div>
      {location.pathname === "/explore" && (
        <div>
          <div className="flex justify-between items-center px-8 mt-5 mb-2">
            <h1 className="text-lg font-bold">{/* {texts.users} */}</h1>
            {searchResults.length > 0 && (
              <button
                onClick={toggleUserList}
                className="text-neutral-600 font-bold text-sm flex items-center"
              >
                <span className="mr-1">
                  {showUserList ? "Show Less" : "Show More"}
                </span>
                {showUserList ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </button>
            )}
          </div>
          {showUserList && searchResults.length > 0 && (
            <div className="w-full h-screen overflow-y-auto">
              <AllUserList data={searchResults} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserList;
