import React from "react";

const TermSp = () => {
  return (
    <div className="max-w-full mx-auto bg-white w-full text-xs  ">
      {/* Add more sections similarly based on the provided content */}
      <h1 className="text-md font-bold mb-4">Legal</h1>
      <p className="mb-4">
        Boca En Boca™ es una marca registrada propiedad de The Peddler Group,
        Inc., una corporación constituida en Texas, constituida el 18 de
        diciembre de 2018.
      </p>
      <h1 className="text-md font-bold mb-4">Términos y condiciones</h1>
      <div className=" ">
        <p className="text-neutral-600 text-xs  mb-6">
          Efectivo a partir del 15 de septiembre de 2024
        </p>

        {/* Table of Contents */}
        <section className="mb-8">
          <ul className="list-decimal list-inside text-neutral-800">
            <li>Introducción</li>
            <li>Definiciones</li>
            <li>Los Servicios Boca En Boca</li>
            <li>Su uso de los servicios de Boca En Boca</li>
            <li>Suscripciones y Pagos</li>
            <li>Contenido y derechos de propiedad intelectual</li>
            <li>Atención al cliente y quejas</li>
            <li>Problemas y disputas</li>
            <li>Cambios a estos términos</li>
            <li>Información de contacto</li>
          </ul>
        </section>

        {/* 1. Introduction */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">1. Introduction</h2>
          <p className="text-neutral-800 mb-4">
            Bienvenido a Boca En Boca™. Estos Términos y condiciones
            ("Términos") rigen su uso de la aplicación móvil Boca En Boca™, el
            sitio web y servicios relacionados (colectivamente, el "Servicio
            Boca En Boca").
          </p>
          <p className="text-neutral-800 mb-4">
            Al utilizar el Servicio Boca En Boca™, usted acepta estos Términos,
            nuestras Política de privacidad y cualquier término adicional que
            podamos proporcionar. si lo haces Si no está de acuerdo, no utilice
            nuestro servicio.
          </p>
          <p className="text-neutral-800 mb-4">
            Estos Términos constituyen un acuerdo legalmente vinculante entre
            usted y The Peddler Group, Inc. ("nosotros", "nos" o "nuestro"), la
            entidad legal responsable del desarrollo y operación del Servicio
            Boca En Boca™.
          </p>
          <p className="text-neutral-800 mb-4">
            Requisito de edad: debes tener al menos 18 años para utilizar Boca
            Servicio En Boca™. Al utilizar el sitio web o la aplicación móvil de
            Boca En Boca, o al crear una cuenta de usuario, usted acepta que
            tiene 18 años o más y que acepta los Términos y condiciones.
          </p>
        </section>

        {/* 2. Definitions */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">2. Definitions</h2>
          <ul className="list-disc list-inside text-neutral-800 mb-4">
            <li>
              "Boca En Boca™" se refiere a la aplicación móvil, el sitio web y
              servicios relacionados proporcionados por The Peddler Group.
            </li>
            <li>
              "El grupo vendedor ambulante, Inc." es la persona jurídica
              responsable de desarrollar y operar el Servicio Boca En Boca™.
            </li>
            <li>
              "Usuario", "usted" o "su" se refiere a cualquier individuo o
              entidad que utilice el Servicio Boca En Boca™.
            </li>
          </ul>
        </section>

        {/* 3. The Boca En Boca Services */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            3. Los Servicios Boca En Boca™
          </h2>
          <p className="text-neutral-800 mb-4">
            <strong>3.1 Descripción del Servicio:</strong> Boca En Boca es una
            plataforma que conecta a buscadores de empleo con empleadores.
            Ofrecemos varias opciones de servicio, incluidas suscripciones
            gratuitas y de pago.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>3.2 Integraciones con Terceros:</strong> Nuestro servicio
            puede integrarse con aplicaciones, sitios web y servicios de
            terceros. El uso de estas integraciones puede estar sujeto a
            términos adicionales de esos terceros.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>3.3 Modificaciones del Servicio:</strong> Nos reservamos el
            derecho de modificar, suspender o descontinuar cualquier parte de
            nuestro servicio en cualquier momento, con o sin previo aviso. No
            seremos responsables ante usted ni ante ningún tercero por cualquier
            modificación, suspensión o descontinuación del servicio.
          </p>
        </section>

        {/* 4. Your Use of the Boca En Boca Services */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            4. Su Uso de los Servicios de Boca En Boca™
          </h2>
          <p className="text-neutral-800 mb-4">
            <strong>4.1 Creación de Cuenta:</strong> Es posible que necesite
            crear una cuenta para utilizar ciertas funciones del servicio Boca
            En Boca™. Usted es responsable de proporcionar información de cuenta
            precisa y actualizada, así como de mantener la confidencialidad de
            sus credenciales de inicio de sesión.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>4.2 Uso Aceptable:</strong> Usted acepta usar el servicio
            Boca En Boca™ en cumplimiento con estos Términos y todas las leyes
            aplicables. No debe:
          </p>
          <ul className="list-disc list-inside text-neutral-800 mb-4">
            <li>Compartir sus credenciales de cuenta con otras personas</li>
            <li>Usar el servicio para fines ilegales o no autorizados</li>
            <li>
              Interferir o interrumpir el servicio o los servidores conectados
              al servicio
            </li>
            <li>
              Intentar obtener acceso no autorizado a cualquier parte del
              servicio
            </li>
            <li>
              Usar el servicio para transmitir virus, malware u otro código
              malicioso
            </li>
          </ul>
          <p className="text-neutral-800 mb-4">
            <strong>4.3 Directrices para los Usuarios:</strong> Hemos
            establecido Directrices para los Usuarios para garantizar una
            experiencia positiva para todos los usuarios. El incumplimiento de
            estas directrices puede resultar en la suspensión o terminación de
            su cuenta.
          </p>
        </section>

        {/* 5. Subscriptions and Payments */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">5. Suscripciones y Pagos</h2>
          <p className="text-neutral-800 mb-4">
            <strong>5.1 Tipos de Suscripción:</strong> Ofrecemos suscripciones
            Gratuitas y de Pago. Las características disponibles en cada tipo de
            suscripción se describen en nuestro sitio web y dentro de la
            aplicación. Los modelos de suscripción Gratuita y de Pago están
            sujetos a cambios a discreción de The Peddler Group, Inc.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>5.2 Facturación y Cancelación:</strong> Las suscripciones de
            Pago se facturan anualmente. Se le cobrará por adelantado por cada
            período de facturación. Puede cancelar su suscripción en cualquier
            momento, con efecto al final de su período de facturación actual. No
            se proporcionarán reembolsos.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>5.3 Cambios de Precio:</strong> Podemos cambiar los precios
            de nuestras suscripciones de vez en cuando. El uso continuado del
            servicio después de que el cambio de precio entre en vigor
            constituye su aceptación del nuevo precio.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>5.4 Pruebas:</strong> Podemos ofrecer pruebas gratuitas o
            con descuento de nuestras suscripciones de Pago. La elegibilidad y
            la duración de la prueba se especificarán al registrarse. A menos
            que se cancele antes del final del período de prueba, su suscripción
            de Pago comenzará automáticamente al final del período de prueba.
          </p>
          <h3 className="text-md font-bold mb-4">Planes de Suscripción</h3>
          <p className="text-neutral-800 mb-4">
            Ofrecemos dos planes de suscripción:
            <ul className="list-disc pl-6">
              <li>
                <strong>Plan Anual:</strong> $9.99 por año
              </li>
              <li>
                <strong>Plan Premium:</strong> $999.99 por año
              </li>
            </ul>
          </p>
          <p className="text-neutral-800 mb-4">
            Ambos planes brindan acceso a funciones exclusivas, según lo
            descrito en la aplicación.
          </p>
          <p className="text-neutral-800 mb-4">
            Su suscripción se renovará automáticamente a menos que la renovación
            automática se desactive al menos 24 horas antes del final del
            período actual.
          </p>
          <p className="text-neutral-800 mb-4">
            Se le cobrará la renovación dentro de las 24 horas previas al final
            del período de suscripción actual.
          </p>
          <p className="text-neutral-800">
            Puede administrar o cancelar su suscripción a través de su cuenta de
            Apple ID / Google Play después de la compra.
          </p>
        </section>

        {/* 6. Content and Intellectual Property Rights */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            6. Contenido y Derechos de Propiedad Intelectual
          </h2>
          <p className="text-neutral-800 mb-4">
            <strong>6.1 Contenido del Usuario:</strong> Puede publicar, subir o
            contribuir con contenido al servicio Boca En Boca™ ("Contenido del
            Usuario"). Usted es el único responsable de su Contenido del Usuario
            y de las consecuencias de publicarlo. NO puede publicar contenido
            que incluya lo siguiente:
          </p>
          <ul className="list-disc list-inside text-neutral-800 mb-4">
            <li>
              Contenido pornográfico, desnudez, sexual o relacionado con drogas
              y sustancias ilegales
            </li>
            <li>
              Contenido discriminatorio u ofensivo basado en edad, raza, género,
              orientación sexual o puntos de vista políticos
            </li>
            <li>Violencia</li>
          </ul>
          <p className="text-neutral-800 mb-4">
            La publicación de dicho contenido resultará en una prohibición
            inmediata. Su cuenta de usuario será eliminada permanentemente, y no
            se emitirán reembolsos. The Peddler Group, Inc. proporcionará a las
            agencias de aplicación de la ley toda la información requerida a su
            solicitud.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>6.2 Concesión de Licencia:</strong> Al publicar Contenido
            del Usuario, otorga a The Peddler Group, Inc. una licencia no
            exclusiva, transferible, sublicenciable, libre de regalías y mundial
            para usar, modificar, mostrar y distribuir su Contenido del Usuario
            en relación con la operación y promoción del servicio Boca En Boca™.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>6.3 Propiedad Intelectual:</strong> El servicio Boca En
            Boca™, incluidos todos los logotipos, marcas registradas y contenido
            no enviado por los usuarios, es propiedad de The Peddler Group, Inc.
            o de sus licenciantes y está protegido por leyes de derechos de
            autor y otras leyes de propiedad intelectual.
          </p>
        </section>

        {/* 7. Customer Support and Complaints */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            7. Soporte al Cliente y Quejas
          </h2>
          <p className="text-neutral-800 mb-4">
            Si necesita asistencia o tiene una queja con respecto al servicio
            Boca En Boca™, puede contactar a nuestro equipo de Soporte al
            Cliente a través de la aplicación o el sitio web. Nos esforzaremos
            por resolver su problema lo más rápido posible.
          </p>
        </section>

        {/* 8. Problems and Disputes */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">8. Problemas y Disputas</h2>
          <p className="text-neutral-800 mb-4">
            <strong>8.1 Exención de Garantía:</strong> El servicio Boca En Boca™
            se proporciona "tal como está" y "según esté disponible", sin
            garantías de ningún tipo, ya sean expresas o implícitas, en la
            máxima medida permitida por la ley aplicable.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>8.2 Limitación de Responsabilidad:</strong> En la máxima
            medida permitida por la ley, The Peddler Group no será responsable
            de ningún daño indirecto, incidental, especial, consecuente o
            punitivo, ni de ninguna pérdida de ganancias o ingresos, ya sea
            directa o indirecta, ni de ninguna pérdida de datos, uso, buena
            voluntad u otras pérdidas intangibles derivadas del uso del servicio
            Boca En Boca™.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>8.3 Indemnización:</strong> Usted acepta indemnizar y eximir
            a The Peddler Group, Inc., sus directores, funcionarios, empleados y
            afiliados de cualquier reclamo, responsabilidad, daño o gasto
            derivado de su uso del servicio Boca En Boca™ o de la violación de
            estos Términos.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>8.4 Resolución de Disputas:</strong> Cualquier disputa
            derivada de estos Términos o de su uso del servicio Boca En Boca™
            será resuelta mediante arbitraje vinculante, realizado de manera
            individual, de acuerdo con la Asociación Americana de Arbitraje.
          </p>
        </section>

        {/* 9. Changes to These Terms */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            9. Cambios en Estos Términos
          </h2>
          <p className="text-neutral-800 mb-4">
            Nos reservamos el derecho de modificar estos Términos en cualquier
            momento. Los cambios entrarán en vigor inmediatamente después de ser
            publicados en el sitio web o la aplicación móvil de Boca En Boca™.
            El uso continuo del servicio Boca En Boca™ constituye su aceptación
            de los Términos actualizados.
          </p>
        </section>

        {/* 10. Información de Contacto */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            10. Información de Contacto
          </h2>
          <p className="text-neutral-800 mb-4">
            Si tiene alguna pregunta sobre estos Términos o el servicio Boca En
            Boca™, por favor contáctenos en{" "}
            <p className="text-orange-500 cursor-pointer">
              support@bocaenboca.com
            </p>
            .
          </p>
          <p>
            Peddler Group, Inc. es el controlador de los datos personales
            procesados bajo estos Términos y Condiciones. Al usar el servicio
            Boca En Boca™, usted reconoce que ha leído, comprendido y acepta
            estar sujeto a estos Términos y Condiciones.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermSp;
