import React, { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useDispatch } from "react-redux"; // Import useDispatch from Redux
import { setSelectedWebProductId } from "../../redux-store/subscription/subscriptionSlice"; // Adjust the import path
import PleaseLoginModal from "../../pages/PleaseLoginModal";
import notfilled from "../../assets/fillcheck (2).svg";
import { analytics } from "../../firebase"; // Import Firebase Analytics
import { logEvent } from "firebase/analytics";
const SubscriptionCard = ({
  textsExtra,
  buttons,
  toggleFormPayment,
  subscriptions,
  setSelectedPrice,
  setSubscriptionIdSetup,
}) => {
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const dispatch = useDispatch(); // Initialize dispatch

  const handleSubscriptionClick = (subscription) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setIsLoginModalVisible(true); // Open login modal if no token
    } else {
      dispatch(setSelectedWebProductId(subscription.web_product_id));
      logEvent(analytics, "subscription_attempt", {
        plan_name: subscription.name,
        price: subscription.price,
      });
      // Set web_product_id in Redux
      setSelectedPrice(subscription.price);
      localStorage.setItem("selectedPrice", subscription.price); // Set the selected price
      localStorage.setItem("subscriptionId", subscription.id);
      toggleFormPayment(); // Proceed with payment if token exists
    }
  };

  return (
    <>
      <div className="p-1 md:p-5 lg:p-5">
        {subscriptions.map((subscription) => {
          // Determine if the current subscription is the Premium Subscription
          const isPremium = subscription.name === "Premium Subscription";

          return (
            <div
              key={subscription.id}
              className={`mt-4 w-full ${
                isPremium ? "bg-white text-black" : "bg-white text-black"
              } shadow-[rgba(0,_0,_0,_0.24)_0px_1px_2px] rounded-2xl flex items-center text-center flex-col space-y-5 py-4`}
            >
              {/* <FaRegCheckCircle className="text-[4rem] mt-10" /> */}
              <img src={notfilled} alt="" />
              <h2
                className={`lg:text-2xl md:text-xl text-lg font-extrabold ${
                  isPremium ? "" : ""
                }`}
              >
                {subscription.name}
              </h2>
              <p
                className={`text-sm lg:text-lg lg:px-14 md:px-14 px-5 text-justify ${
                  isPremium ? "" : ""
                }`}
              >
                {subscription.description}
              </p>
              <span
                className={`text-xl font-bold ${
                  isPremium ? "text-[#E7831D]" : "text-[#E7831D]"
                }`}
              >
                ${subscription.price} / {subscription.interval}
              </span>
              <button
                className={`lg:p-2 md:p-2 p-2 lg:px-20 md:px-10 px-4 ${
                  isPremium
                    ? "bg-[#404041] text-white"
                    : "bg-[#404041] text-white"
                } font-bold rounded-full shadow-sm w-8/12 mb-3 hover:scale-95 hover:shadow-lg`}
                onClick={() => handleSubscriptionClick(subscription)} // Pass subscription data
              >
                {buttons.subscribeNow}
              </button>
            </div>
          );
        })}
      </div>
      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
    </>
  );
};

export default SubscriptionCard;
