import React from "react";
import notfilled from "../../assets/fillcheck (2).svg";
import { analytics } from "../../firebase"; // Import Firebase Analytics
import { logEvent } from "firebase/analytics";

const UpgradePlan = ({ textsExtra, subscriptionData }) => {
  const handleUpgrade = () => {
    // ✅ Log the upgrade event
    logEvent(analytics, "upgrade_subscription", {
      new_price: 999.0,
      previous_price: subscriptionData.price,
      timestamp: Date.now(),
    });
  };

  const handleDowngrade = () => {
    // ✅ Log the downgrade event
    logEvent(analytics, "downgrade_subscription", {
      new_price: 9.99,
      previous_price: subscriptionData.price,
      timestamp: Date.now(),
    });
  };

  return (
    <div
      className={`mt-4 w-12/12 md:w-11/12 lg:w-11/12 mx-auto shadow-[rgba(0,_0,_0,_0.24)_0px_1px_2px] rounded-2xl flex items-center text-center flex-col space-y-8 py-4`}
    >
      <img src={notfilled} alt="Not Filled Check" />

      {subscriptionData.price === "9.99" ? (
        <>
          <h2 className="text-sm md:text-xl lg:text-xl font-extrabold text-[#404041]">
            {textsExtra.Upgrade}
          </h2>
          <p className="text-xs lg:text-sm px-5 md:px-14 lg:px-14 text-justify text-[#404041]">
            {textsExtra.UpgradeText}
          </p>
        </>
      ) : (
        <>
          <h2 className="text-xl font-extrabold text-[#404041]">
            {textsExtra.Downgrade}
          </h2>
          <p className="text-xs lg:text-sm px-14 text-justify text-[#404041]">
            {textsExtra.DowngradeText}
          </p>
        </>
      )}

      {subscriptionData.price === "999.00" && (
        <span className="text-xl font-bold text-[#E7831D]">$9.99/Year</span>
      )}

      {subscriptionData.price === "9.99" ? (
        <button
          className="py-2 flex text-center items-center justify-center bg-[#404041] text-white text-xs md:text-sm lg:text-sm lg:px-10 md:px-10 px-5 font-bold rounded-full shadow-sm w-8/12 mb-3 hover:scale-95 hover:shadow-lg"
          onClick={handleUpgrade}
        >
          {textsExtra.UpgradeButton} <span className="ml-2">$ 999 /Year</span>
        </button>
      ) : (
        <button
          className="py-2 flex justify-between items-center text-center bg-[#404041] text-white text-xs md:text-sm lg:text-sm lg:px-20 md:px-10 px-5 font-bold rounded-full shadow-sm w-8/12 mb-3 hover:scale-95 hover:shadow-lg"
          onClick={handleDowngrade}
        >
          {textsExtra.DowngradeButton}
        </button>
      )}
    </div>
  );
};

export default UpgradePlan;
