import React, { useState, useEffect } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";

const CityInput = ({ value, option, onChangecity, error, name }) => {
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(option);

  const toggleOptionsVisibility = () => {
    setOptionsVisible(!isOptionsVisible);
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setInputValue(input);

    if (input) {
      setFilteredOptions(
        option.filter((city) =>
          city.name.toLowerCase().includes(input.toLowerCase())
        )
      );
      setOptionsVisible(true);
    } else {
      setFilteredOptions(option);
      setOptionsVisible(false);
    }
  };

  const handleOptionClick = (city) => {
    setInputValue(city.name);
    setOptionsVisible(false);
    onChangecity(city.id); // Pass the city ID directly
  };

  useEffect(() => {
    setFilteredOptions(option);
  }, [option]);

  return (
    <div className="flex flex-col w-1/2">
      <div className="bg-[#F2F2F2] rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full flex justify-between items-center px-2 relative">
        <input
          value={inputValue}
          onChange={handleInputChange}
          placeholder={name}
          className="w-10/12 h-full p-2 bg-transparent outline-none text-sm"
        />

        <RiArrowDropDownLine
          className="text-3xl cursor-pointer"
          onClick={toggleOptionsVisibility}
        />
      </div>
      {isOptionsVisible && (
        <div className=" absolute mt-12 w-44 max-h-44 overflow-y-auto bg-neutral-50 ml-5 rounded-md z-10   shadow-lg">
          {filteredOptions.map((city) => (
            <div
              key={city.id}
              className="p-1 cursor-pointer hover:shadow-md hover:bg-neutral-700 hover:text-white "
              onClick={() => handleOptionClick(city)}
            >
              {city.name}
            </div>
          ))}
        </div>
      )}
      {error && (
        <p className="text-[#E7831D] text-xs mt-1 text-left ml-5">{error}</p>
      )}
    </div>
  );
};

export default CityInput;
