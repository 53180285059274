import React from "react";

const Term = () => {
  return (
    <div className="max-w-full mx-auto  bg-white w-full text-xs  ">
      {/* Add more sections similarly based on the provided content */}
      <h1 className="text-md font-bold mb-4">Legal</h1>
      <p className="mb-4">
        Boca En Boca™ is a trademark owned by The Peddler Group, Inc., a
        corporation formed in Texas, established on December 18, 2018.
      </p>
      <h1 className="text-md font-bold mb-4">Terms & Conditions</h1>
      <div className=" ">
        <p className="text-neutral-600 text-xs  mb-6">
          Effective as of September 15, 2024
        </p>

        {/* Table of Contents */}
        <section className="mb-8">
          <ul className="list-decimal list-inside text-neutral-800">
            <li>Introduction</li>
            <li>Definitions</li>
            <li>The Boca En Boca Services</li>
            <li>Your Use of the Boca En Boca Services</li>
            <li>Subscriptions and Payments</li>
            <li>Content and Intellectual Property Rights</li>
            <li>Customer Support and Complaints</li>
            <li>Problems and Disputes</li>
            <li>Changes to These Terms</li>
            <li>Contact Information</li>
          </ul>
        </section>

        {/* 1. Introduction */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">1. Introduction</h2>
          <p className="text-neutral-800 mb-4">
            Welcome to Boca En Boca™. These Terms & Conditions ("Terms") govern
            your use of the Boca En Boca™ mobile application, website, and
            related services (collectively, the "Boca En Boca Service").
          </p>
          <p className="text-neutral-800 mb-4">
            By using the Boca En Boca™ Service, you agree to these Terms, our
            Privacy Policy, and any additional terms we may provide. If you do
            not agree, please do not use our service.
          </p>
          <p className="text-neutral-800 mb-4">
            These Terms constitute a legally binding agreement between you and
            The Peddler Group, Inc. ("we," "us," or "our"), the legal entity
            responsible for developing and operating the Boca En Boca™ Service.
          </p>
          <p className="text-neutral-800 mb-4">
            Age Requirement - You must be at least 18 years old to use the Boca
            En Boca™ Service. By using the Boca En Boca website or mobile app,
            or by creating a User Account, you consent that you are 18 or older
            and that you agree to the Terms & Conditions.
          </p>
        </section>

        {/* 2. Definitions */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">2. Definitions</h2>
          <ul className="list-disc list-inside text-neutral-800 mb-4">
            <li>
              "Boca En Boca™" refers to the mobile application, website, and
              related services provided by The Peddler Group.
            </li>
            <li>
              "The Peddler Group, Inc." is the legal entity responsible for
              developing and operating the Boca En Boca™ Service.
            </li>
            <li>
              "User," "you," or "your" refers to any individual or entity using
              the Boca En Boca™ Service.
            </li>
          </ul>
        </section>

        {/* 3. The Boca En Boca Services */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            3. The Boca En Boca™ Services
          </h2>
          <p className="text-neutral-800 mb-4">
            <strong>3.1 Service Description:</strong> Boca En Boca is a platform
            that connects job seekers and employers. We offer various service
            options, including free and paid subscriptions.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>3.2 Third-Party Integrations:</strong> Our service may
            integrate with third-party applications, websites, and services.
            Your use of these integrations may be subject to additional terms
            from those third parties.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>3.3 Service Modifications:</strong> We reserve the right to
            modify, suspend, or discontinue any part of our service at any time,
            with or without notice. We will not be liable to you or any third
            party for any modification, suspension, or discontinuation of the
            service.
          </p>
        </section>

        {/* 4. Your Use of the Boca En Boca Services */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            4. Your Use of the Boca En Boca™ Services
          </h2>
          <p className="text-neutral-800 mb-4">
            <strong>4.1 Account Creation:</strong> You may need to create an
            account to use certain features of the Boca En Boca™ Service. You
            are responsible for providing accurate and up-to-date account
            information and maintaining the confidentiality of your login
            credentials.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>4.2 Acceptable Use:</strong> You agree to use the Boca En
            Boca™ Service in compliance with these Terms and all applicable
            laws. You must not:
          </p>
          <ul className="list-disc list-inside text-neutral-800 mb-4">
            <li>Share your account credentials with others</li>
            <li>Use the service for any illegal or unauthorized purposes</li>
            <li>
              Interfere with or disrupt the service or servers connected to the
              service
            </li>
            <li>
              Attempt to gain unauthorized access to any part of the service
            </li>
            <li>
              Use the service to transmit any viruses, malware, or other
              malicious code
            </li>
          </ul>
          <p className="text-neutral-800 mb-4">
            <strong>4.3 User Guidelines:</strong> We have established User
            Guidelines to ensure a positive experience for all users. Failure to
            comply with these guidelines may result in the suspension or
            termination of your account.
          </p>
        </section>

        {/* 5. Subscriptions and Payments */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            5. Subscriptions and Payments
          </h2>
          <p className="text-neutral-800 mb-4">
            <strong>5.1 Subscription Types:</strong> We offer both Free and Paid
            Subscriptions. The features available in each subscription type are
            described on our website and within the app. The Free and Paid
            Subscription models are subject to change at the discretion of The
            Peddler Group, Inc.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>5.2 Billing and Cancellation:</strong> Paid Subscriptions
            are billed annually. You will be charged in advance for each billing
            period. You may cancel your subscription at any time, effective at
            the end of your current billing period. No refunds will be provided.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>5.3 Price Changes:</strong> We may change our subscription
            prices from time to time. Continued use of the service after the
            price change takes effect constitutes your acceptance of the new
            price.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>5.4 Trials:</strong> We may offer free or discounted trials
            of our Paid Subscriptions. Trial eligibility and duration will be
            specified at sign-up. Unless cancelled before the end of the trial
            period, your Paid Subscription will automatically begin at the end
            of the trial period.
          </p>
          <h3 className="text-md font-bold mb-4">Subscription Plans</h3>
          <p className="text-neutral-800 mb-4">
            We offer two subscription plans:
            <ul className="list-disc pl-6">
              <li>
                <strong>Annual Plan:</strong> $9.99 per year
              </li>
              <li>
                <strong>Premium Plan:</strong> $999.00 per year
              </li>
            </ul>
          </p>
          <p className="text-neutral-800 mb-4">
            Both plans provide access to exclusive features as described within
            the App.
          </p>
          <p className="text-neutral-800 mb-4">
            Your subscription will automatically renew unless auto-renew is
            turned off at least 24 hours before the end of the current period.
          </p>
          <p className="text-neutral-800 mb-4">
            You will be charged for renewal within 24 hours prior to the end of
            the current subscription period.
          </p>
          <p className="text-neutral-800">
            You can manage or cancel your subscription through your Apple ID
            account / Google Play account settings after purchase.
          </p>
        </section>

        {/* 6. Content and Intellectual Property Rights */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            6. Content and Intellectual Property Rights
          </h2>
          <p className="text-neutral-800 mb-4">
            <strong>6.1 User Content:</strong> You may post, upload, or
            contribute content to the Boca En Boca™ Service ("User Content").
            You are solely responsible for your User Content and the
            consequences of posting or publishing it. You may NOT post content
            that includes the following:
          </p>
          <ul className="list-disc list-inside text-neutral-800 mb-4">
            <li>
              Pornographic, nudity, sexual or illegal drug and substance related
            </li>
            <li>
              Discriminatory or offensive content based on age, race, gender,
              sexual orientation, or political views
            </li>
            <li>Violence</li>
          </ul>
          <p className="text-neutral-800 mb-4">
            Posting such content will result in an immediate ban. Your user
            account will be removed permanently, and no refunds will be issued.
            The Peddler Group, Inc. will provide law enforcement agencies with
            all inquiries at their request.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>6.2 License Grant:</strong> By posting User Content, you
            grant The Peddler Group, Inc. a non-exclusive, transferable,
            sub-licensable, royalty-free, worldwide license to use, modify,
            display, and distribute your User Content in connection with
            operating and promoting the Boca En Boca™ Service.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>6.3 Intellectual Property:</strong> The Boca En Boca™
            Service, including all trademarks, logos, and content not submitted
            by users, is the property of The Peddler Group, Inc. or its
            licensors and is protected by copyright and other intellectual
            property laws.
          </p>
        </section>

        {/* 7. Customer Support and Complaints */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">
            7. Customer Support and Complaints
          </h2>
          <p className="text-neutral-800 mb-4">
            If you need assistance or have a complaint regarding the Boca En
            Boca™ Service, you can contact our Customer Support team through the
            app or website. We will strive to address your issue as quickly as
            possible.
          </p>
        </section>

        {/* 8. Problems and Disputes */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">8. Problems and Disputes</h2>
          <p className="text-neutral-800 mb-4">
            <strong>8.1 Warranty Disclaimer:</strong> The Boca En Boca™ Service
            is provided "as is" and "as available," without any warranties of
            any kind, either express or implied, to the fullest extent
            permissible under applicable law.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>8.2 Limitation of Liability:</strong> To the fullest extent
            permitted by law, The Peddler Group shall not be liable for any
            indirect, incidental, special, consequential, or punitive damages,
            or any loss of profits or revenues, whether incurred directly or
            indirectly, or any loss of data, use, goodwill, or other intangible
            losses resulting from your use of the Boca En Boca™ Service.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>8.3 Indemnification:</strong> You agree to indemnify and
            hold The Peddler Group, Inc., its directors, officers, employees,
            and affiliates harmless from any claims, liabilities, damages, or
            expenses arising from your use of the Boca En Boca™ Service or
            violation of these Terms.
          </p>
          <p className="text-neutral-800 mb-4">
            <strong>8.4 Dispute Resolution:</strong> Any disputes arising from
            these Terms or your use of the Boca En Boca Service will be resolved
            through binding arbitration, conducted on an individual basis, in
            accordance with the American Arbitration Association.
          </p>
        </section>

        {/* 9. Changes to These Terms */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">9. Changes to These Terms</h2>
          <p className="text-neutral-800 mb-4">
            We reserve the right to modify these Terms at any time. Changes will
            take effect immediately upon being posted on the Boca En Boca™
            website or mobile app. Your continued use of the Boca En Boca™
            Service constitutes your acceptance of the updated Terms.
          </p>
        </section>

        {/* 10. Contact Information */}
        <section className="mb-8">
          <h2 className="text-md font-bold mb-4">10. Contact Information</h2>
          <p className="text-neutral-800 mb-4">
            If you have any questions about these Terms or the Boca En Boca™
            Service, please contact us at{" "}
            <a
              href="mailto:support@bocaenboca.com"
              className="text-orange-500 cursor-pointer"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@bocaenboca.com
            </a>
            .
          </p>
          <p>
            Peddler Group, Inc. is the data controller for personal data
            processed under this Terms & Conditions. By using the Boca En Boca
            Service, you acknowledge that you have read, understood, and agree
            to be bound by these Terms & Conditions
          </p>
        </section>
      </div>
    </div>
  );
};

export default Term;
