import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import RadioRole from "./RadioRole"; // Import the RadioRole component
import jobseeker from "../../assets/jobseeker.jpg"; // Ensure correct path to the image
import employer from "../../assets/employer.jpg"; // Ensure correct path to the image
import Loading from "../loading/Loading"; // Import the loading spinner component
import logo from "../../assets/mainlogonew.png";
import StateInput from "../../pages/StateInput"; // Import StateInput component
import CityInput from "../../pages/CityInput"; // Import CityInput component
import { useSelector } from "react-redux";
import translations from "../../languages";
import axios from "axios";
import CityLoading from "../loading/CityLoading";

const RoleSelectionModal = ({
  visible,
  onClose,
  onSelectRole,
  setSelectedCityId,
  setSelectedStateId,
  setPhoneNumber,
  phoneNumber,
}) => {
  const [selectedRole, setSelectedRole] = useState(null); // No default selection
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state
  const [selectedCity, setSelectedCity] = useState(""); // State for selected city
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);

  const selectedLanguage = useSelector((state) => state.language.language);
  const textsTwo = translations[selectedLanguage].signUpPage;
  const texts = translations[selectedLanguage].roleSocial;

  useEffect(() => {
    fetchStates();
  }, []);

  const handleRoleChange = (role) => {
    setSelectedRole(role); // Store the selected role without registering automatically
  };

  const handleRegister = () => {
    if (!selectedRole) {
      alert("Please select a role before proceeding.");
      return;
    }
    setIsLoading(true);
    onSelectRole(selectedRole); // Now register only when button is clicked
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const handleStateChange = (stateId) => {
    setSelectedStateId(stateId);
    fetchCities(stateId);
  };

  const handleCityChange = (cityId) => {
    setSelectedCityId(cityId);
  };

  const fetchStates = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/states/233`
      );
      if (response.data.response_code === "default_200") {
        setStates(response.data.content);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
    setIsLoading(false);
  };

  const fetchCities = async (stateId) => {
    setLoadingTwo(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/cities/${stateId}`
      );
      if (response.data.response_code === "default_200") {
        setCities(response.data.content);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
    setLoadingTwo(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      closeIcon={null}
    >
      <div className="flex flex-col space-y-5 items-center text-center lg:p-5 md:p-5 p-3 ">
        {/* Show loading spinner or images based on loading state */}
        {isLoading ? (
          <div className="flex justify-center items-center">
            <Loading /> {/* Display loading spinner */}
          </div>
        ) : (
          <div className="space-y-4">
            <p className="font-inter font-semibold text-2xl leading-7 text-center px-8 py-3 mt-2">
              {texts.text}
            </p>
            <img
              src={logo}
              alt="logo"
              className="w-full h-auto px-16 lg:py-8 md:py-10 p-10 mb-4"
            />

            {/* State & City Selection */}
            <div className="flex space-x-3">
              <StateInput
                onChangestate={handleStateChange}
                option={states}
                name={textsTwo.selectState}
              />
              {loadingTwo ? (
                <CityLoading />
              ) : (
                <CityInput
                  onChangecity={handleCityChange}
                  option={cities}
                  name={textsTwo.selectCity}
                />
              )}
            </div>

            {/* Role Selection */}
            <RadioRole
              selected={selectedRole}
              setSelected={handleRoleChange}
              plans={["job-seeker", "employer"]}
            />

            {/* Register Button */}
            <button
              onClick={handleRegister}
              className="bg-[#404041] py-2 cursor-pointer rounded-full mb-2 mt-3 font-bold text-white hover:bg-neutral-500 hover:scale-95 transition-transform ease-in-out duration-300 disabled:opacity-50 w-full"
              disabled={!selectedRole || isLoading}
            >
              {isLoading ? "Registering..." : "Register"}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RoleSelectionModal;
