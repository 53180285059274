import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inputValue: "",
};

const inputValueSlice = createSlice({
  name: "inputValue",
  initialState,
  reducers: {
    setInputValue: (state, action) => {
      state.inputValue = action.payload;
    },
    clearInputValue: (state) => {
      state.inputValue = "";
    },
  },
});

export const { setInputValue, clearInputValue } = inputValueSlice.actions;

export default inputValueSlice.reducer;
