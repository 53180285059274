import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";
export const logTrafficSource = (source, medium) => {
  logEvent(analytics, "traffic_source:WEB", {
    source: source,
    medium: medium,
    timestamp: Date.now(),
  });
};
export const logDeviceInfo = (deviceType, os) => {
  logEvent(analytics, "device_info", {
    device_type: deviceType,
    os: os,
    timestamp: Date.now(),
  });

  console.log("Logged Device Info:", { deviceType, os });
};

// ✅ Get Device Type & OS Automatically
export const detectDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  let deviceType = "Unknown";
  let os = "Unknown";

  if (/Mobi|Android|iPhone/i.test(userAgent)) {
    deviceType = "Mobile";
  } else if (/Tablet|iPad/i.test(userAgent)) {
    deviceType = "Tablet";
  } else {
    deviceType = "Desktop";
  }

  if (/Windows/i.test(userAgent)) os = "Windows";
  else if (/Mac/i.test(userAgent)) os = "MacOS";
  else if (/Linux/i.test(userAgent)) os = "Linux";
  else if (/Android/i.test(userAgent)) os = "Android";
  else if (/iOS|iPhone|iPad/i.test(userAgent)) os = "iOS";

  logDeviceInfo(deviceType, os);
};
