import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import moment from "moment-timezone";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";

const NotificationCards = ({
  notifications,
  list,
  showAll,
  setShowAll,
  toggleShowAll,
}) => {
  const animations = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1, originY: 0 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: "spring", stiffness: 350, damping: 40 },
  };

  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].buttons;
  const [type, setType] = useState();
  const navigate = useNavigate();
  const limitedNotifications = showAll
    ? notifications
    : notifications.slice(0, list);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleNotificationClick = async (notification) => {
    try {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      // Check if the title starts with "New Post Created by"
      if (notification.title.startsWith("New Post Created by")) {
        // Redirect to the post page with the updates_id
        navigate(`/post/${notification.updates_id}`);
      } else if (notification.title === "Bookmarked your profile") {
        navigate(`/user/${notification.by_user}`);
      } else {
        navigate(`/dm/${notification.channel_id}/${notification.by_user}`);
      }

      // Mark the notification as read
      const response = await axios.put(
        `${baseUrl}/api/v1/customer/notification/read/${notification.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const getTimeAgo = (created_at) => {
    const now = moment();
    const createdAt = moment(created_at);

    const monthsDiff = now.diff(createdAt, "months");
    const daysDiff = now.diff(createdAt, "days");
    const hoursDiff = now.diff(createdAt, "hours");
    const minutesDiff = now.diff(createdAt, "minutes");
    const secondsDiff = now.diff(createdAt, "seconds");

    if (monthsDiff > 0) {
      return `${monthsDiff} month${monthsDiff > 1 ? "s" : ""} ago`;
    } else if (daysDiff > 0) {
      return `${daysDiff} day${daysDiff > 1 ? "s" : ""} ago`;
    } else if (hoursDiff > 0) {
      return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
    } else if (minutesDiff > 0) {
      return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
    } else {
      return `${secondsDiff} second${secondsDiff > 1 ? "s" : ""} ago`;
    }
  };

  const filteredNotifications = limitedNotifications.filter(
    (notification) => notification.description !== null
  );

  const [showMore, setShowMore] = useState({});

  // Function to toggle the read more/less state
  const toggleShowMore = (id) => {
    setShowMore((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="z-30 bg-transparent">
      {filteredNotifications.map((notification, index) => {
        const { profile_image, type, first_name, last_name } =
          notification.sender_user;
        const isEmployer = type.toLowerCase() === "employer";
        const profileImageURL =
          profile_image && profile_image !== "default.png"
            ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${profile_image}`
            : null;

        return (
          <div
            key={index}
            className={`py-4 px-1 rounded-2xl mb-4 w-full relative hover:scale-95 cursor-pointer transition-transform ease-in-out duration-300 overflow-y-auto ${
              notification.is_active === 0 ? "bg-white" : "bg-neutral-100"
            }`}
            onClick={() => handleNotificationClick(notification)}
          >
            <div className="flex">
              {profileImageURL ? (
                <img
                  className={`w-10 h-10 rounded-full p-0 object-cover ${
                    isEmployer
                      ? "ring-4 ring-[#E7831D]"
                      : "ring-4 ring-neutral-700"
                  }`}
                  src={profileImageURL}
                  alt="Profile"
                />
              ) : (
                <FaUserCircle
                  className={`w-10 h-10 text-3xl rounded-full p-0 object-cover text-neutral-300 ${
                    isEmployer
                      ? "ring-4 ring-[#E7831D]"
                      : "ring-4 ring-neutral-700"
                  }`}
                />
              )}

              <div className="flex flex-col ml-2">
                <span className="text-md font-bold text-neutral-600">
                  {first_name} {last_name}
                </span>
                <span
                  className={`text-xs flex items-center ${
                    type === "job-seeker" ? "text-black" : "text-[#E7831D]"
                  }`}
                >
                  {capitalizeFirstLetter(type)}
                  <IoIosCheckmarkCircle className="ml-1" />
                </span>
              </div>

              <div className="flex flex-grow justify-end text-xs">
                <p>{getTimeAgo(notification.updated_at)}</p>
              </div>
            </div>

            {notification.description && (
              <div className="mt-2 flex flex-col ml-12 text-xs">
                {notification.description
                  .replace(/\r\n/g, "\n")
                  .split("\n")
                  .slice(0, showMore[notification.id] ? undefined : 1)
                  .map((line, lineIdx) => (
                    <div key={lineIdx}>
                      {line.split(" ").map((word, wordIdx) => (
                        <React.Fragment key={wordIdx}>
                          {word.startsWith("#") ? (
                            <span className="text-[#E7831D] font-bold">
                              {word}
                            </span>
                          ) : (
                            <span>{word}</span>
                          )}
                          {wordIdx < line.split(" ").length - 1 && " "}
                        </React.Fragment>
                      ))}
                      <br />
                    </div>
                  ))}
                {notification.description.split("\n").length > 1 && (
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleShowMore(notification.id);
                    }}
                    className="text-[#E7831D] mt-1 mr-auto"
                  >
                    {showMore[notification.id] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        );
      })}

      {notifications.length > 3 && !showAll && (
        <div className="relative inset-0 flex z-40 justify-center items-center">
          {/* Blurred background */}
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="absolute z-40 bg-white blur-3xl h-64 w-full"></div>
          </div>

          {/* Show More Button */}
          <button
            onClick={toggleShowAll}
            className="absolute z-40 text-md cursor-pointer text-white rounded-full  bg-[#404041] px-7 py-2 bottom-5 hover:bg-[#545455] hover:scale-95 font-bold"
          >
            {texts.showMore}
          </button>
        </div>
      )}
    </div>
  );
};

export default NotificationCards;
