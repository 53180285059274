import React from "react";

const NotiLoader = () => {
  return (
    <div className="w-full h-20 flex px-8 py-4 ">
      <div className="w-10 h-10 bg-neutral-300 rounded-full animate-pulse"></div>
      <div className="flex flex-col  gap-3 ml-5">
        <div className=" h-5 w-52 bg-neutral-300 animate-pulse rounded-full"></div>
        <div className=" h-5 w-40 bg-neutral-300 animate-pulse rounded-full"></div>
        <div className=" h-5 w-72 bg-neutral-300 animate-pulse rounded-full"></div>
      </div>
      <div className="ml-auto w-14 h-3 bg-neutral-300 rounded-full animate-pulse "></div>
    </div>
  );
};

export default NotiLoader;
