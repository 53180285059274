import React, { useState } from "react";
import { Modal, Input, DatePicker, Space, Spin } from "antd";
import { IoClose } from "react-icons/io5";
import { LoadingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import dayjs from "dayjs";

const ExtraFieldModal = ({
  closeModal,
  fieldName,
  fieldType,
  userData,
  fetchUserData,
  feildId,
}) => {
  const initialValue = userData?.[fieldName] || "";
  const [fieldValue, setFieldValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  console.log(fieldValue, "fieldValue");

  const handleSave = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const BASE_URL = process.env.REACT_APP_API_BASE_URL;

      if (!token || !BASE_URL) {
        console.error("Authorization token or Base URL is missing");
        setLoading(false);
        return;
      }

      const url = `${BASE_URL}/api/v1/user/update/${userData.id}`;
      const formData = new FormData();

      formData.append("extra_field_id", feildId);
      formData.append("field_value", fieldValue);

      await axios.post(url, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      fetchUserData();
      closeModal();
    } catch (error) {
      console.error("Error updating extra field:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={true}
      onCancel={closeModal}
      footer={null}
      closeIcon={<IoClose className="text-neutral-50" />}
    >
      <div className="w-full h-auto py-5 px-10 flex flex-col space-y-4">
        <h1 className="text-lg text-neutral-700 font-bold">{fieldName}</h1>

        {/* Conditional Rendering Based on fieldType */}
        {fieldType === "text" && (
          <TextArea
            rows={6}
            className="border-neutral-500 rounded-2xl"
            value={fieldValue}
            onChange={(e) => setFieldValue(e.target.value)}
            placeholder={`Enter ${fieldName}`}
          />
        )}

        {fieldType === "number" && (
          <Input
            type="number"
            className="border-neutral-500 rounded-2xl"
            value={fieldValue}
            onChange={(e) => setFieldValue(e.target.value)}
            placeholder={`Enter ${fieldName}`}
          />
        )}

        {fieldType === "date" && (
          <DatePicker
            className="w-full border-neutral-500 rounded-2xl"
            value={fieldValue ? dayjs(fieldValue) : null}
            onChange={(date, dateString) => setFieldValue(dateString)}
          />
        )}

        <button
          className={`w-full p-3 text-white font-bold bg-[#404041] hover:scale-95 rounded-full ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handleSave}
          disabled={loading}
        >
          {loading && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </Modal>
  );
};

export default ExtraFieldModal;
