import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ProfileBanner from "../../profile/ProfileBanner";
import MainLogoLoader from "../loading/MainLogoLoader";
import Postscopy from "../homepage/Postscopy";
import UserProfileBanner from "../../profile/UserProfileBanner";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import UserList from "../mini-components/UserList";
import { BsThreeDots } from "react-icons/bs";
import bblogo from "../../assets/276pxlogo.svg";
import { Toaster, toast } from "sonner";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
import UserActionsMenu from "../../profile/UserActionsMenu";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";
const UserProfile = ({ setSidebarOpen }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const searchText = useSelector((state) => state.search.searchText);
  const [postList, setPostList] = useState([]);
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].headings;
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [channelLoading, setChannelLoading] = useState(false);
  const [bookmarkLoading, setBookmarkLoading] = useState({});
  const [bookmarkDeleted, setBookmarkDeleted] = useState(false);
  const [bookmarkSet, setBookmarkSet] = useState(false);
  const [userExists, setUserExists] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Get base URL from environment variable
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        // Get token and userId from local storage
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");

        // Fetch user details
        let userDetailsResponse;
        if (userId) {
          // If userId exists in localStorage, use the standard API for user details
          userDetailsResponse = await axios.get(
            `${baseURL}/api/v1/user/details/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          // If userId is not in localStorage, use the "share" API for user details
          userDetailsResponse = await axios.get(
            `${baseURL}/api/v1/user/details/share/${id}`
          );
        }

        const fetchedUserDetails = userDetailsResponse.data.content;
        setUserDetails(fetchedUserDetails);

        // Check if the fetched user ID matches the stored user ID
        if (fetchedUserDetails.id === userId) {
          setIsMyProfile(true);
        }

        // Fetch post list
        let postListResponse;
        if (userId) {
          // If userId exists, use the standard post list API
          postListResponse = await axios.get(
            `${baseURL}/api/v1/user/post/list?limit=100&offset=1&user_id=${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          // If userId is not in localStorage, use the offline post list API
          postListResponse = await axios.get(
            `${baseURL}/api/v1/user/post/listoffline?limit=100&offset=1&user_id=${id}`
          );
        }

        setPostList(postListResponse.data.content.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details and post list:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, bookmarkDeleted, bookmarkSet]);

  useEffect(() => {
    if (searchText.length > 0) {
      navigate("/explore");
    }
  }, [searchText, navigate]);
  const createChatChannel = async (postId, toUser) => {
    try {
      setChannelLoading((prev) => ({ ...prev, [postId]: true }));
      const authToken = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/chat/create-channel`;
      const data = {
        reference_id: "",
        reference_type: "support",
        post_id: postId,
        to_user: toUser,
      };

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const channelId = response.data.content.id;

      setChannelLoading((prev) => ({ ...prev, [postId]: false }));

      toast(" Chat channel created successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });

      navigate(`/dm/${channelId}/${toUser}`);
    } catch (error) {
      setChannelLoading((prev) => ({ ...prev, [postId]: false }));
      toast("Error creating chat channel. Please try again later.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    }
  };
  const handleReportPost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/user-action`,
        { message: "block", post_id: postId, comment_type: "block" },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast("Post reported successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
      // fetchPosts(true);
      // handleNewPostCreation();
    } catch (error) {
      console.error("Error reporting post:", error);
      // toast("Error reporting post. Please try again later.", { style: { backgroundColor: "#333", color: "#fff" } });
    }
  };
  const handleBookmark = async (userId) => {
    try {
      setBookmarkLoading((prev) => ({ ...prev, [userId]: true }));
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/profile/bookmark/store`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast(" User bookmarked successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
      setBookmarkSet(true);
    } catch (error) {
      console.error("Error bookmarking user:", error);
      toast("Error bookmarking user. Please try again later.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    } finally {
      setBookmarkLoading((prev) => ({ ...prev, [userId]: false }));
    }
  };

  const handleDeleteBookmark = async (userId) => {
    try {
      setBookmarkLoading((prev) => ({ ...prev, [userId]: true }));
      const authToken = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/profile/bookmark/delete/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast("User bookmark deleted successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
      setBookmarkDeleted(true);
    } catch (error) {
      console.error("Error deleting bookmark:", error);
      toast("Error deleting bookmark. Please try again later.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    } finally {
      setBookmarkLoading((prev) => ({ ...prev, [userId]: false }));
    }
  };
  const handleRepost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/store`,
        {
          description: null, // Always empty spaces
          link_post_id: postId, // Pass the post ID as link_post_id
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Time-Zone": userTimeZone,
          },
        }
      );
      logEvent(analytics, "post_reposted", {
        post_id: postId,
        time_zone: userTimeZone,
        timestamp: new Date().toISOString(),
      });

      toast("Post reposted successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });

      // Call to refresh posts or any related actions
      // handleNewPostCreation();
      navigate("/profile");
    } catch (error) {
      console.error("Error reposting post:", error);
      toast("Error reposting post. Please try again later.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    }
  };
  const handleBlock = async (userId) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/customer/chat/user-action`,
        {
          message: "block",
          user_id: userId,
          comment_type: "block",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast("User blocked", {
          style: {
            backgroundColor: "#333",
            color: "#fff",
          },
        });
        navigate("/");
      }
    } catch (error) {
      console.error("Error blocking user:", error);
      toast("Error blocking user. Please try again later.", {
        style: {
          backgroundColor: "#333",
          color: "#fff",
        },
      });
    }
  };
  return (
    <div className="scrollbar-hide w-full paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-[0.85rem]"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" />
        </div>
      </div>
      <div className="w-full flex">
        {!loading && (
          <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
            {isMyProfile ? texts.myProfile : texts.profile}
          </h1>
        )}
        {!isMyProfile && userExists && (
          <span className="ml-auto mr-2 ">
            <UserActionsMenu
              onBookmark={() => handleBookmark(id)}
              onBlock={() => handleBlock(id)}
            />
          </span>
        )}
      </div>

      <hr />

      {loading ? (
        <MainLogoLoader />
      ) : (
        userDetails && (
          <UserProfileBanner
            userDetails={userDetails}
            isMyProfile={isMyProfile}
            handleBookmark={handleBookmark}
            handleDeleteBookmark={handleDeleteBookmark}
          />
        )
      )}
      <hr />
      {/* Render post list here */}

      <UserList />
      <div className="mt-10">
        <Postscopy
          postdatas={postList}
          createChatChannel={createChatChannel}
          channelLoading={channelLoading}
          handleRepost={handleRepost}
          handleReportPost={handleReportPost}
        />
      </div>
    </div>
  );
};

export default UserProfile;
