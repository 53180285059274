// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB76pc03sZmKMtgA6N_gz82vlkavcLScUQ",
  authDomain: "boca-en-boca-baa46.firebaseapp.com",
  projectId: "boca-en-boca-baa46",
  storageBucket: "boca-en-boca-baa46.firebasestorage.app",
  messagingSenderId: "351088734491",
  appId: "1:351088734491:web:9fb744bd3641b4a22563be",
  measurementId: "G-0NC4ETLK5G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { analytics };
