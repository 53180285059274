import { Fragment, useState, useEffect } from "react";
import moment from "moment";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaSync,
} from "react-icons/fa";
import { CiClock2 } from "react-icons/ci";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import DropdownMenucalone from "./DropdownMenucalone";
import DropdownIconMenu from "./DropdownIconMenu";
import NotiLoader from "../loading/NotiLoader";
import { FaUserCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import InterviewListItem from "./InterviewListItem";
import NointerviewFoundImg from "../../assets/nointerview.svg";
import InterviewFilters from "./InterviewFilters";
import MonthDropdown from "./MonthDropdown";
import YearDropdown from "./YearDropdown";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MonthView({
  options,
  currentMonth,
  setCurrentMonth,
  getEventsForDay,
  selectedDate,
  setSelectedDate,
  setInitialCal,
  loading,
  interviewList,
  setParamStatusFilter,
  paramStatusFilter,
  initialMonth,
  initialDate,
}) {
  // Generate all days for the current month
  const generateDaysForMonth = (month) => {
    console.log(interviewList, "inter");
    const startOfMonth = month.clone().startOf("month").startOf("week");
    const endOfMonth = month.clone().endOf("month").endOf("week");
    const days = [];
    let day = startOfMonth.clone().subtract(1, "day");

    while (day.isBefore(endOfMonth, "day")) {
      days.push({
        date: day.add(1, "day").clone(),
        isCurrentMonth: day.isSame(month, "month"),
        isToday: day.isSame(moment(), "day"),
        events: getEventsForDay(day),
      });
    }
    return days;
  };

  const days = generateDaysForMonth(currentMonth);

  // Add state to hold interview counts
  const [interviewCounts, setInterviewCounts] = useState({});

  useEffect(() => {
    // Calculate interview counts on mount and set state
    const counts = {};
    interviewList.forEach((interview) => {
      const date = moment(interview.interview_date).format("YYYY-MM-DD");
      counts[date] = (counts[date] || 0) + 1;
    });
    setInterviewCounts(counts);
  }, [interviewList]); // Only run when interviewList changes

  // Function to get interview count for a specific date
  const getInterviewCountForDate = (date) => {
    return interviewCounts[date.format("YYYY-MM-DD")] || 0;
  };

  // Function to reset the calendar to the initial state
  const resetCalendar = () => {
    setCurrentMonth(initialMonth);
    setSelectedDate(initialDate);
  };

  // Add state to track active button
  const [activeButton, setActiveButton] = useState(null);

  // Function to handle button click
  const handleButtonClick = (buttonType) => {
    resetCalendar();
    setActiveButton(buttonType);
    if (buttonType === "today") {
      setSelectedDate(moment());
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-none">
        <header className="px-1 md:px-2 lg:px-2 flex items-center justify-between py-5 ">
          <MonthDropdown
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
          />
          <YearDropdown
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
          />
          <button
            onClick={() => handleButtonClick("all")}
            className={`ml-2 hover:scale-105 ${
              activeButton === "all"
                ? "bg-[#404041] text-white"
                : "bg-white text-xs md:text-sm lg:text-sm font-medium text-neutral-700 hover:bg-neutral-50 border-2 rounded-full "
            } px-4 md:px-6 lg:px-6 py-2 rounded-full`}
          >
            All
          </button>
          <button
            onClick={() => handleButtonClick("today")}
            className={`ml-2 hover:scale-105 ${
              activeButton === "today"
                ? "bg-[#404041] text-white"
                : "bg-white text-xs md:text-sm lg:text-sm font-medium text-neutral-700 hover:bg-neutral-50 border-2 rounded-full "
            } px-4 md:px-6 lg:px-6 py-2 rounded-full`}
          >
            Today
          </button>
        </header>
        <hr />

        <div className="lg:flex-none">
          <div className="grid grid-cols-7 gap-px border-b border-neutral-200 bg-neutral-200 text-center text-[10px] sm:text-xs font-semibold leading-6 text-neutral-700 lg:flex-none">
            {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day, idx) => (
              <div key={idx} className="bg-neutral-100 py-1 h-8 sm:h-10">
                {day}
              </div>
            ))}
          </div>
        </div>

        <div className="flex bg-neutral-200 text-[10px] sm:text-xs leading-6 text-neutral-700">
          <div className="isolate grid w-full grid-cols-7 grid-rows-5 gap-[0.05rem] sm:gap-[0.10rem]">
            {days.map((day) => (
              <button
                key={day.date.format("YYYY-MM-DD")}
                type="button"
                onClick={() => setSelectedDate(day.date)}
                className={classNames(
                  day.isCurrentMonth
                    ? "bg-white h-full"
                    : "bg-neutral-50 h-full",
                  selectedDate &&
                    day.date.isSame(selectedDate, "day") &&
                    "bg-[#cac9c9] text-black",
                  day.isToday && "font-semibold",
                  day.isToday && " bg-[#E7831D] text-white",
                  !day.isCurrentMonth && !day.isToday && "text-neutral-500",
                  "flex flex-col py-2 sm:py-3 hover:bg-neutral-100 hover:text-black focus:z-10 border-1 border-black"
                )}
              >
                <time
                  dateTime={day.date.format("YYYY-MM-DD")}
                  className={classNames(
                    "flex h-8 w-8 sm:h-8 sm:w-8 items-center justify-center rounded-full ml-auto mr-1",
                    day.isToday
                      ? "bg-[#E7831D] font-bold text-[11px] sm:text-md text-white "
                      : getInterviewCountForDate(day.date) > 0 && !day.isToday
                      ? "bg-[#393837] text-white h-6"
                      : ""
                  )}
                >
                  {day.date.date()}
                </time>
                <span className="sr-only">{day.events.length} events</span>
              </button>
            ))}
          </div>
        </div>

        <hr />
      </div>

      {/* Interview list */}
      <div className=" mt-1 flex-grow overflow-y-auto h-1/2  ">
        {loading ? (
          <p className="text-center text-neutral-500">
            <NotiLoader /> <NotiLoader /> <NotiLoader /> <NotiLoader />{" "}
            <NotiLoader />
          </p>
        ) : interviewList.length === 0 ? (
          <>
            <div className="w-full flex flex-col justify-center items-center text-center h-64">
              <img
                src={NointerviewFoundImg}
                alt="no interview icon"
                className="w-20"
              />
              <p className="text-center text-neutral-400">
                No interviews found
              </p>
            </div>
          </>
        ) : (
          <div>
            {/* <InterviewFilters
              paramStatusFilter={paramStatusFilter}
              setParamStatusFilter={setParamStatusFilter}
            /> */}
            <ul className="space-y-4  scrollbar-hide">
              {interviewList.map((interview) => (
                <InterviewListItem
                  key={interview.id}
                  interview={interview}
                  channelid={interview.channel_id}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
