import React, { useState, useEffect } from "react";
import SearchMessage from "./SearchMessage";
import ChatComponent from "./ChatComponent";
import ChatList from "./ChatList";
import axios from "axios";
import { Toaster, toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";

const AllMessagesOfPost = (props) => {
  const { messagedata, options, OpenChat, chatOpened, setSidebarOpen } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedChat, setSelectedChat] = useState(null);
  console.log(selectedChat, "selectedChat");
  const [chatlistData, setChatlistData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getChatList = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const currentRoute = window.location.pathname;
    const isBookmarkRoute = currentRoute.includes("bookmarks"); // Adjust this based on your route structure

    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const bookmarkValue = isBookmarkRoute ? "yes" : "no";
      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/channel-list`,
        {
          params: {
            limit: "100",
            offset: 1,
            bookmark: bookmarkValue,
            post_id: id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setChatlistData(response.data.content.data);
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
      setLoading(false);
    }
  };

  const blockOrReportUser = async (userId, actionType) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("No authentication token found.");
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/customer/chat/user-action`,
        {
          message: actionType === "block" ? "block" : "report",
          user_id: userId,
          comment_type: actionType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getChatList();
      toast.success(`User ${actionType}ed `, {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
      // navigate(-1);
      navigate("/messages");
      setLoading(false);
      return response.data;
    } catch (error) {
      console.error("Error blocking or reporting user:", error);
      setLoading(false);
      throw error;
    }
  };
  const blockOrReportUsermain = async (userId, actionType) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("No authentication token found.");
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/customer/chat/user-action`,
        {
          message: actionType === "block" ? "block" : "report",
          user_id: userId,
          comment_type: actionType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getChatList();
      toast.success(`User ${actionType}ed `, {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      console.error("Error blocking or reporting user:", error);
      setLoading(false);
      throw error;
    }
  };
  const deleteChats = async (channelId) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/delete-channel/${channelId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      getChatList();
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
      setLoading(false);
    }
  };
  const pinToTheTopChats = async (channelId) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseUrl}/api/v1/customer/chat/pin-to-top`,
        { channel_id: channelId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      getChatList();
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
      setLoading(false);
    }
  };
  const removePinFromTopChats = async (channelId) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${baseUrl}/api/v1/customer/chat/pin-to-top/delete/${channelId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      getChatList();
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getChatList();
  }, []);

  const openChat = (chatData) => {
    console.log(chatData, "chatData");

    setSelectedChat([chatData]);
    OpenChat(true);
    navigate(`/dm/${chatData.channel_id}/${chatData.user_id}`);
  };
  return (
    <div className="w-full   ">
      {!chatOpened && (
        <div>
          <ChatList
            messagedata={chatlistData}
            openChat={openChat}
            removePinFromTopChats={removePinFromTopChats}
            loading={loading}
            deleteChats={deleteChats}
            pinToTheTopChats={pinToTheTopChats}
            getChatList={getChatList}
            setSidebarOpen={setSidebarOpen}
            blockOrReportUser={blockOrReportUsermain}
          />
        </div>
      )}
      <div>
        {chatOpened && (
          <ChatComponent
            chatData={selectedChat}
            OpenChat={OpenChat}
            blockOrReportUser={blockOrReportUser}
            deleteChats={deleteChats}
          />
        )}
      </div>
    </div>
  );
};

export default AllMessagesOfPost;
