import React, { useState } from "react";
import SearchMessage from "./SearchMessage";
import AllMessages from "./AllMessages";
import messagedata from "./messageData";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import { setChatOpened } from "../../redux-store/chat/chatSlice";
import bblogo from "../../assets/276pxlogo.svg";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
import { useParams } from "react-router-dom";
import AllMessagesOfPost from "./AllMessagesOfPost";
const MessageMain = ({ setSidebarOpen }) => {
  const [chatOpened, OpenChat] = useState(false);
  const { id } = useParams();

  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.language);
  const isChatOpened = useSelector((state) => state.chat.chatOpened);
  const texts = translations[selectedLanguage].headings;
  const options = translations[selectedLanguage].headings;

  const handleChatToggle = () => {
    const newState = !chatOpened;
    OpenChat(newState);
    dispatch(setChatOpened(newState));
  };

  return (
    <div className="scrollbar-hide w-auto  ">
      {/* {!chatOpened && (
        <div className=" sidebar-complete-hide">
          <h1 className="text-lg font-bold mb-2 px-8">{texts.messages}</h1>
        </div>
      )} */}

      {/* <SearchMessage /> */}

      <AllMessagesOfPost
        messagedata={messagedata}
        postId={id}
        options={options}
        chatOpened={chatOpened}
        OpenChat={handleChatToggle}
        setSidebarOpen={setSidebarOpen}
      />
    </div>
  );
};

export default MessageMain;
