import React from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../assets/mainlogonew.png";
import { useSelector } from "react-redux";
import translations from "../languages";
import { IoClose } from "react-icons/io5";
import { analytics } from "../firebase"; // Import Firebase analytics
import { logEvent } from "firebase/analytics"; // Import logEvent

const LogoutModal = ({
  visible,
  onCancel,
  title = "Please Log In",
  content = "You need to log in to access this feature.",
}) => {
  const navigate = useNavigate();
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].logoutmodal;

  const LogOut = () => {
    // Firebase event logging
    logEvent(analytics, "logout", {
      source: "web",
      timestamp: Date.now(),
    });

    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={null}
      footer={null}
      closeIcon={<IoClose className="text-neutral-50" />}
    >
      <div className="flex flex-col space-y-5 items-center text-center lg:p-5 md:p-5 p-1 pb-10">
        <p className="font-inter font-semibold text-xl md:text-xl lg:text-2xl leading-7 text-center px-4 md:px-12 lg:px-12 py-5 lg:py-3 mt-4">
          {texts.text}
        </p>

        <img
          src={logo}
          alt="logo"
          className="w-full h-auto px-16 lg:py-8 md:py-10 p-5 mb-4"
        />

        <div className="w-full flex gap-5 px-10">
          <button
            className="py-2 bg-neutral-50 text-[#404041] font-bold rounded-full shadow-sm text-sm md:text-xl lg:text-xl hover:scale-95 hover:shadow-lg w-1/2 lg:mt-4 mt-0 border-2 border-[#404041]"
            onClick={onCancel}
          >
            {texts.cancel}
          </button>
          <button
            className="py-2 bg-[#404041] text-white font-bold rounded-full shadow-sm text-sm md:text-xl lg:text-xl hover:scale-95 hover:shadow-lg w-1/2 lg:mt-4 mt-0"
            onClick={LogOut}
          >
            {texts.confirm}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
