import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeNotificationCount: 0,
};

const activeNotificationSlice = createSlice({
  name: "activeNotifications",
  initialState,
  reducers: {
    setActiveNotificationCount: (state, action) => {
      state.activeNotificationCount = action.payload;
    },
  },
});

export const { setActiveNotificationCount } = activeNotificationSlice.actions;

export default activeNotificationSlice.reducer;
