// userSearchSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userSearchText: "", // Initial state for user search text
};

const userSearchSlice = createSlice({
  name: "userSearch",
  initialState,
  reducers: {
    setUserSearchText: (state, action) => {
      state.userSearchText = action.payload;
    },
    clearUserSearchText: (state) => {
      state.userSearchText = "";
    },
  },
});

export const { setUserSearchText, clearUserSearchText } =
  userSearchSlice.actions;
export default userSearchSlice.reducer;
