import React, { useEffect } from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const MainInputComponent = ({
  type,
  name,
  value,
  onChange,
  error,
  placeholder,
}) => {
  const inputType = type === "number" ? "tel" : type;

  useEffect(() => {
    if (type === "number" && value && !value.startsWith("+")) {
      onChange({ target: { name, value: "+" + value } });
    }
  }, [type, value, onChange, name]);

  const handleChange = (e) => {
    if (type === "number") {
      // Ensure the phone number starts with "+" if it's not already
      const phoneValue = e.target.value.replace(/^\+?/, ""); // Remove any existing "+"
      const formattedValue = "+" + phoneValue; // Add "+" to the beginning
      e.target.value = formattedValue;
    }
    onChange(e);
  };

  return (
    <div className="flex flex-col w-full">
      {type === "number" ? (
        <ReactPhoneInput
          country={"us"} // Default country code
          value={value}
          onChange={(phone) => onChange({ target: { name, value: phone } })}
          inputProps={{
            className:
              "bg-[#F2F2F2] p-2 px-12 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm",
            placeholder: "Enter new phone number",
          }}
        />
      ) : (
        <input
          type={inputType}
          name={name}
          value={value}
          onChange={handleChange}
          className="bg-[#F2F2F2] p-2 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
          placeholder={placeholder}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
        />
      )}
      {error && (
        <p className="text-[#E7831D] text-xs mt-1 text-left ml-5">{error}</p>
      )}
    </div>
  );
};

export default MainInputComponent;
