import React from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../assets/mainlogonew.png";

const GuestModeModal = ({
  visible,
  onCancel,
  title = "Please Log In",
  content = "You need to log in to access this feature.",
}) => {
  const navigate = useNavigate();

  return (
    <Modal visible={visible} onCancel={onCancel} title={null} footer={null}>
      <div className="flex flex-col space-y-5 items-center text-center lg:p-5 md:p-5 p-3 h-[18rem]">
        <p className="font-inter font-semibold text-2xl leading-7 text-center px-12 py-5 mt-2">
          Guest Mode
        </p>
        {/* <img src={logo} alt="logo" className="w-full h-auto px-16  " /> */}
        <p className="font-inter text-md leading-7 text-center px-12">
          You are currently in guest mode. Some features are limited. To unlock
          the full functionality, please log in or create a free account.
        </p>

        <button
          className="p-3 bg-[#404041] text-white  rounded-full shadow-sm text-lg hover:scale-95 hover:shadow-lg hover:shadow-orange-400 w-10/12 lg:mt-4 mt-0"
          onClick={() => navigate("/login")}
        >
          Take me to Login/Signup
        </button>
      </div>
    </Modal>
  );
};

export default GuestModeModal;
