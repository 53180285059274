import React, { useState } from "react";
import { FaRegEdit, FaUserCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { LuShare } from "react-icons/lu";
import { CiLocationOn } from "react-icons/ci";
import { IoQrCodeOutline } from "react-icons/io5";
import { IoPersonOutline } from "react-icons/io5";
import lady from "../assets/lady.jpg";
import axios from "axios"; // Import Axios
import profileicon from "../assets/Profile.svg";
import { useDispatch, useSelector } from "react-redux";
import translations from "../languages";
import { motion } from "framer-motion";
import BookmarksIcon from "../assets/Bookmarks.svg";
import { setSearchText } from "../redux-store/postSearch/searchSlice";
import ShareProfilePopUp from "../components/mini-components/ShareProfilePopUp";
import nobanner from "../assets/nocover.png";
import { IoBookmarkSharp } from "react-icons/io5";
import { FaRegBookmark } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";
import HeaderSvg from "../assets/HeaderImg.svg";
import { Spin } from "antd";
import PleaseLoginModal from "../pages/PleaseLoginModal";

const UserProfileBanner = ({
  userDetails,
  isMyProfile,
  handleBookmark,
  handleDeleteBookmark,
  bookmarkLoading,
}) => {
  console.log(userDetails, "userDetails");
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const selectedLanguage = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const texts = translations[selectedLanguage].profileExtras;
  const [profileShareModalVisible, setProfileShareModalVisible] =
    useState(false);
  const [profileUrl, setprofileUrl] = useState("");
  const [isImageOverlayVisible, setImageOverlayVisible] = useState(false);
  const [bookMarkFetching, setBookMarkFetching] = useState(false);
  const handleshare = (id) => {
    setprofileUrl(`${window.location.origin}/user/${id}`);
    setProfileShareModalVisible(true);
    // navigate(`/post/${id}`);
  };
  function formatResponseTime(seconds) {
    const days = Math.ceil(seconds / (24 * 60 * 60));
    const hours = Math.ceil((seconds % (24 * 60 * 60)) / (60 * 60));

    if (days > 30) {
      return "over a month";
    }
    let formatted = "";
    if (days > 0) {
      formatted += `${days} day${days > 1 ? "s" : ""} `;
    }
    if (hours > 0) {
      formatted += `${hours} hour${hours > 1 ? "s" : ""} `;
    }
    return formatted ? `${formatted.trim()} ${texts.responseTime}` : "";
  }
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };
  const handleTagClick = (tag) => {
    dispatch(setSearchText(tag));
  };
  const handleProfileImageClick = () => {
    setImageOverlayVisible(true);
  };

  const closeOverlay = () => {
    setImageOverlayVisible(false);
  };
  const parseTextWithHash = (text, handleTagClick) => {
    if (!text.includes("#")) {
      return text;
    }
    const parts = text.split(/(#\S+)/g); // Split by # and keep hashtags
    return parts.map((part, index) =>
      part.startsWith("#") ? (
        <span
          key={index}
          className="text-[#E7831D] font-bold cursor-pointer"
          onClick={() => handleTagClick(part)}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const maxPreviewLength = 100; // Adjust this value based on your design needs
  // if (!userDetails?.about_me) return null;

  const shouldTruncate = userDetails.about_me.length > maxPreviewLength;
  const previewText = shouldTruncate
    ? userDetails.about_me.slice(0, maxPreviewLength) + "..."
    : userDetails.about_me;
  const handleBookMarkClick = async () => {
    const userId = localStorage.getItem("userId");

    // Check if user is logged in
    if (!userId) {
      // Optionally, you can show an alert or a message to the user here

      return; // Prevent further action if no user is logged in
    }

    setBookMarkFetching(true);

    if (userDetails.bookmark_status === "yes") {
      await handleDeleteBookmark(userDetails.id);
    } else {
      await handleBookmark(userDetails.id);
    }

    setBookMarkFetching(false);
  };

  return (
    <>
      <div className="mb-2">
        <div className="relative">
          <img
            className="h-32 w-full object-cover lg:h-48 filter grayscale"
            src={
              userDetails.banner_image !== "default.png" &&
              userDetails.banner_image
                ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.banner_image}`
                : HeaderSvg
            }
            alt=""
            style={{
              filter: "neutralscale(100%)",
            }}
          />
        </div>
        <div className="mx-auto ml-2 md:ml-8 lg:ml-8 max-w-full px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            {userDetails?.profile_image && userDetails.profile_image !== "" && (
              <div className="flex relative">
                {userDetails.type === "job-seeker" ? (
                  <img
                    className=" rounded-full  h-20 w-20  md:h-32 md:w-32 lg:h-32 lg:w-h2object-cover ring-4 ring-neutral-500"
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`}
                    alt=""
                    onClick={handleProfileImageClick}
                  />
                ) : (
                  <img
                    className=" rounded-full  h-20 w-20 md:h-32 md:w-32 lg:h-32 lg:w-h2 object-cover ring-4 ring-[#E7831D] "
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`}
                    alt=""
                    onClick={handleProfileImageClick}
                  />
                )}
              </div>
            )}
            {(!userDetails?.profile_image ||
              userDetails.profile_image === "") && (
              <div className="flex relative">
                <FaUserCircle
                  className={`h-20 w-20 md:h-32 md:w-32 lg:h-32 lg:w-h2 bg-white text-[2rem] rounded-full p-0 object-cover text-neutral-300 cursor-pointer ${
                    userDetails?.type?.toLowerCase() === "employer"
                      ? "ring-4 ring-[#E7831D]"
                      : "ring-4 ring-neutral-700"
                  }`}
                  onClick={() => navigate(`/user/${userDetails?.id}`)}
                />
              </div>
            )}
          </div>
          <div className="mt-3  min-w-0 flex-1 ">
            <h1 className="truncate lg:text-2xl md:text-xl text-md font-bold text-neutral-900">
              {userDetails.first_name} {userDetails.last_name}
            </h1>
          </div>
          <div className="flex items-center">
            <span className="lg:text-sm md:text-sm text-xs text-neutral-600">
              {userDetails.username}
            </span>
            {userDetails.type?.toLowerCase() === "employer" && (
              <span className="lg:text-sm md:text-sm text-xs text-[#E7831D]   flex items-center ml-2">
                {capitalizeFirstLetter(userDetails.type)}{" "}
                <FaCheckCircle className="ml-2" />
              </span>
            )}
            {userDetails.type === "job-seeker" && (
              <span className="lg:text-sm md:text-sm text-xs text-neutral-600   flex items-center ml-2">
                {capitalizeFirstLetter(userDetails.type)}
                <FaCheckCircle className="ml-2" />
              </span>
            )}
            <div className="ml-auto flex justify-center items-center gap-6">
              {!isMyProfile && (
                <>
                  {bookMarkFetching ? (
                    <Spin
                      indicator={<LoadingOutlined spin />}
                      className="ml-auto w-2/4 text-neutral-500"
                    />
                  ) : (
                    <>
                      {userDetails.bookmark_status === "yes" ? (
                        <IoBookmarkSharp
                          className={`text-2xl cursor-pointer hover:scale-105 ${
                            !localStorage.getItem("userId")
                              ? "cursor-not-allowed text-neutral-400"
                              : ""
                          }`}
                          onClick={handleBookMarkClick}
                        />
                      ) : (
                        <FaRegBookmark
                          className={`text-2xl hover:scale-105 ${
                            !localStorage.getItem("userId")
                              ? "opacity-40 text-neutral-600"
                              : "cursor-pointer"
                          }`}
                          onClick={() => {
                            if (!localStorage.getItem("userId")) {
                              setIsLoginModalVisible(true);
                            } else {
                              handleBookMarkClick();
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              <LuShare
                className="text-2xl cursor-pointer hover:scale-105"
                onClick={() => handleshare(userDetails.id)}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-3 mt-1 ">
            <div className="flex gap-3 text-neutral-700 mb-3 lg:text-sm md:text-sm text-xs flex-wrap">
              {userDetails.no_of_post} {texts.posts}{" "}
              <span>
                {" "}
                {userDetails.no_of_bookmark} {texts.bookmarks}
              </span>{" "}
              {userDetails.avg_chat_response !== null && (
                <span>{formatResponseTime(userDetails.avg_chat_response)}</span>
              )}
            </div>
            {userDetails.city && (
              <div className="flex lg:text-sm md:text-sm text-xs ">
                <CiLocationOn className="text-xl mr-2 " />
                <span className="mt-[0.2rem]">
                  {userDetails.city && userDetails.city.name}
                  {", "}
                  {userDetails.state && userDetails.state.name}
                  {", "}
                  {userDetails.country && userDetails.country.name}
                </span>
              </div>
            )}

            {userDetails.about_me && (
              <div className="flex lg:text-sm md:text-sm text-xs mt-1">
                <img
                  src={profileicon}
                  alt="profile icon"
                  className="mr-2 h-5 w-5"
                />
                <span className="mt-[0.2rem]"> {texts.about}</span>
              </div>
            )}
            {userDetails.about_me && (
              <p className="mx-7 text-justify lg:text-sm md:text-sm text-xs">
                <p className="whitespace-pre-wrap">
                  {parseTextWithHash(
                    expanded ? userDetails.about_me : previewText
                  )}
                </p>
                {shouldTruncate && (
                  <button
                    className="text-[#E7831D] font-medium mt-2"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? "Read Less" : "Read More"}
                  </button>
                )}
              </p>
            )}
          </div>
        </div>
      </div>
      {isImageOverlayVisible && (
        <motion.div
          className="fixed inset-0 z-[99999] flex items-center flex-col  bg-neutral-700 bg-opacity-30 backdrop-blur-md"
          onClick={closeOverlay}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className="relative"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <img
              className="h-52 w-52 md:h-72 md:w-72 lg:h-72 lg:w-72 rounded-full object-cover mt-24"
              src={
                userDetails.profile_image !== "default.png" &&
                userDetails.profile_image
                  ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoHAKWHhA6UuX-UPdizhi5K4IsByBpEJMX8Q&s"
              }
              alt="Profile"
            />
          </motion.div>
          <motion.div
            className="relative lg:w-96 md:w-72 w-64"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.9, ease: "easeInOut" }}
          >
            <div className="absolute mt-10 left-1/2 w-full transform -translate-x-1/2 flex  gap-4 p-2 md:p-5 lg:p-3 bg-white rounded-xl shadow-lg">
              <div className="flex items-center flex-col justify-center gap-2 w-1/2">
                <div className="p-2 border-2 border-neutral-400 rounded-full">
                  <IoQrCodeOutline
                    className="text-[#E7831D] text-3xl cursor-pointer hover:scale-105"
                    onClick={() => handleshare(userDetails.id)}
                  />
                </div>

                <button
                  className="text-neutral-700 font-semibold text-sm "
                  onClick={() => handleshare(userDetails.id)}
                >
                  QR Code
                </button>
              </div>
              <div className="flex items-center flex-col justify-center gap-2 w-1/2">
                <div className="p-2 border-2 border-neutral-400 rounded-full">
                  <LuShare
                    className="text-[#E7831D] text-3xl cursor-pointer hover:scale-105"
                    onClick={() => handleshare(userDetails.id)}
                  />
                </div>

                <button
                  className="text-neutral-700 font-semibold text-sm"
                  onClick={() => handleshare(userDetails.id)}
                >
                  Share Profile
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
      {profileShareModalVisible && (
        <ShareProfilePopUp
          profileShareModalVisible={profileShareModalVisible}
          setProfileShareModalVisible={setProfileShareModalVisible}
          profileUrl={profileUrl}
        />
      )}
      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
    </>
  );
};

export default UserProfileBanner;
